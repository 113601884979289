import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  font-family: "Work Sans";
`;

export const Label = styled.label`
  font-family: "Work Sans";
`;

export const InputStyled = styled.input`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text};
  font-size: 18px;
  font-family: "Work Sans";

  border: none;
  border-radius: 8px;

  height: 36px;
  padding: 10px;
`;

export const ContainerPriceAmount = styled.div`
  display: flex;
  width: 100%;

  margin-bottom: 10px;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const AmountContainerLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const LabelAmount = styled.div`
  display: flex;

  font-size: 22px;
  font-family: "Work Sans";

  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  justify-content: center;
`;

export const ButtonAmount = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 5px;

  font-size: 14px;
  font-family: "Work Sans";
  font-weight: 600;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ButtonAdd = styled.button`
  background-color: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.white};
  height: 40px;
  width: 100%;

  font-size: 14px;
  font-family: "Work Sans";
  font-weight: 600;

  border: none;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ButtonGroup = styled.button<{ isActive: boolean }>`
  background-color: ${(props) => props.isActive ? props.theme.primary : props.theme.button};
  color: ${(props) => props.theme.white};
  height: 40px;

  font-size: 12px;
  font-family: "Work Sans";
  font-weight: 500;

  border: none;
  border-radius: 5px;
  padding-left: 20px;
  padding: 10px;
  margin: 4px;

  &:hover {
    filter: opacity(0.9);
  }
`;

// List
export const LabelAmountExtra = styled.div`
  font-size: 16px;
  font-family: "Work Sans";

  padding-left: 8px;
  padding-right: 8px;
  font-weight: 600;
`;

export const ButtonAmountExtra = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 28px;
  width: 28px;
  border: none;
  border-radius: 5px;

  font-size: 14px;
  font-family: "Work Sans";
  font-weight: 600;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ListTitle = styled.h2`
  font-size: 14px;
  font-weight: 700;
  font-family: "Work Sans";
  padding-top: 12px;
  padding-bottom: 10px;
`;

export const Outline = styled.div<{ isError: boolean }>`
  font-size: 12px;
  font-weight: 600;
  font-family: "Work Sans";

  border: 2px solid
    ${(props) => (props.isError ? props.theme.cancel : props.theme.button)};
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${(props) =>
    props.isError ? props.theme.cancel : props.theme.button};

  margin-top: 5px;
  margin-bottom: 8px;
`;

export const RadioOutline = styled.div`
  border: 2px solid ${(props) => props.theme.button};
  border-radius: 5px;
  padding: 10px;
  color: ${(props) => props.theme.button};
  width: 20px;
  height: 20px;
`;

export const RadioChecked = styled.div`
  border: 2px solid ${(props) => props.theme.primary};
  border-radius: 5px;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.button};

  width: 20px;
  height: 20px;

  padding: 10px;
`;

export const ContainerListMain = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.background};
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ContainerListTitle = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.background};
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ListTitleContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

export const ListTitleMoreContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.button};
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const FormFooter = styled.div`
  width: 50vw;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 90vw;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 600;
`;

export const CenterFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  font-size: 18px;
  font-family: "Work Sans";
  font-weight: 700;
  padding-right: 10px;
`;

export const RightFooter = styled.div`
  display: flex;
  flex: 0;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  font-size: 12px;
  font-family: "Work Sans";
  font-weight: 500;
`;

export const Tag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  border-radius: 30px;

  padding-left: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  margin-right: 20px;
`;

export const SectionModal = styled.p`
  color: ${(props) => props.theme.text};
  font-size: 18px;
  font-family: "Work Sans";
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 5px;
`;

export const ContainerPrice = styled.div`
  flex: 2;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
`;

export const ContainerProduct = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 5px;
  background-color: ${(props) => props.theme.background};
  border-radius: 5px;
  padding: 10px;
`;

export const ContainerExtra = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const GroupContainer = styled.div`
  display: block;
  width: 100%;
  margin-left: 10px;
`;

export const LabelTitle = styled.label`
  font-size: 12px;
  font-family: "Work Sans";
  margin-bottom: 5px;
  color: #000000;
`;

export const LabelTitleList = styled.p`
  color: ${(props) => props.theme.text};
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 700;

  margin-bottom: 5px;
`;

export const ImageDefault84 = styled.img.attrs(() => ({
  //src: "home-image.svg",
  src: "../../media/default.jpg",
}))`
  width: 84px;
  height: 84px;
  border-radius: 5px;
  margin-left: 10px;
  object-fit: cover;
`;

export const LabelPrice = styled.p`
  color: ${(props) => props.theme.text};

  font-size: 15px;
  font-weight: 700;
  font-family: "Work Sans";

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtonList = styled.div`
  display: flex;
  flex-direction: row;

  border-top: 1px solid ${(props) => props.theme.background};
  padding-top: 0px;
  padding-bottom: 0px;

  min-height: 100px;

  cursor: pointer;
`;