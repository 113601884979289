import {
  Container,
  Form,
  FormTitle,
  LeftContainer,
  RightContainer,
  ContainerTitle,
  ImageOnChef,
  ImageMap,
  ImagePhone,
  Label,
  ImageCart,
  ContainerOpened,
  LabelOpend,
  ContainerProduct,
  ContainerPrice,
  ContainerButtonList,
  LabelPrice,
  GroupContainer,
  ContainerPriceModal,
  LabelPriceModal,
  LabelPriceOldModal,
  PromotionContainerModal,
  SectionModal,
  ContainerPriceAmount,
  PriceContainer,
  AmountContainer,
  LabelAmount,
  ButtonAmount,
  ButtonAmountExtra,
  LabelAmountExtra,
  ListTitle,
  ContainerListTitle,
  ListTitleContainer,
  ListTitleMoreContainer,
  Outline,
  Footer,
  FormFooter,
  ContainerFooter,
  RightFooter,
  LabelTitleList,
  LabelPriceOldLeft,
  LabelPriceLeft,
  ContainerPriceLeft,
  ContainerListMain,
  AmountContainerLeft,
  ButtonAdd,
  CenterFooter,
  LeftContainerLeft,
  RightContainerRight,
  ImageDelivery,
  TitleCard,
  ImageDefault64,
  ImageDefault84,
  ImageTime,
  ImageArrowTop,
  FormGroup,
  ImageCartStore,
  LabelPromotion,
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef } from "react";
import ShowModal from "../../components/ShowModal";
import { InputGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  ICart,
  ICartProduct,
  IExtra,
  IProduct,
  ISize,
  IStep,
  IStepOption,
  IStore,
  IGroup,
  IDistance,
  IDistrict,
} from "../../classes/interfaces";
import api from "../../classes/api";
import { auth } from "../../classes/auth";
import { DualRing } from "react-spinners-css";
import ModalFracionados from "../../components/ModalFracionados";
import { round2, round2string } from "../../classes/numbers";
import { Button, Checkbox, Tab, Tabs } from "@mui/material";

const imgTop = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  marginBottom: "5px",
};

interface StoreProps {
  styleSale?: "menu";
}

export default function Store({ styleSale }: StoreProps) {
  const navigate = useNavigate();
  const { suffix, tableId } = useParams<{ suffix: string; tableId: string }>();
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState({ status: "create", token: "" });
  const [company, setCompany] = React.useState<IStore>();
  const [groups, setGroups] = React.useState<IGroup[]>();
  const [modalProduct, setModalProduct] = React.useState<{
    open: boolean;
    product: IProduct | null;
  }>({ open: false, product: null });
  const [productsSize, setProductsSize] = React.useState<IProduct[]>([]);
  const [steps, setSteps] = React.useState<IStep[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [isTaxas, setIsTaxas] = React.useState(false);
  const [modalFracionado, setModalFracionado] = React.useState<{
    open: boolean;
    product: ICartProduct | null;
  }>({ open: false, product: null });

  const tabForce = useRef(false);

  function to(id: string) {
    const element = document.getElementById(id);
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
      });
  }

  /*const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(17),
      fontFamily: "Work Sans",
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: 10,
      marginBottom: 10,
    },
    details: {
      display: "block",
      padding: 0,
      paddingLeft: 10,
      paddingRight: 10,
    },
    checked: {
      color: teal[400],
      "&$checked": {
        color: teal[600],
      },
      margin: 0,
      marginLeft: 10,
      padding: 0,
    },
  }));

  const classes = useStyles();*/

  const defaultCart: ICart = {
    grouping_last: 0,
    product_last: 0,
    products: [],
    payments: [],
    total: 0.0,
    styleSale: "menu",
  };

  const sessionCart = sessionStorage.getItem("@menuchef/cart");
  const cart = sessionCart === null ? defaultCart : JSON.parse(sessionCart);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    tabForce.current = true;
    try {
      setValue(newValue);

      if (groups) {
        const id = groups[newValue].id;
        if (id) {
          const key = `group${id}`;
          to(key);
        }
      }
    } finally {
      setTimeout(() => (tabForce.current = false), 1000);
    }
  };

  function openModal(product: IProduct) {
    if (company?.status !== "opened") {
      toast("Ops... empresa está fechada no momento", {
        type: "error",
        autoClose: 3000,
      });
      return;
    }
    if ((product.amount || 0) <= 0) {
      product.amount = 1;
    }
    const prod = {
      id: product.id,
      name: product.name,
      description: product.description,
      price: product.price,
      promotionalPrice: product.promotionalPrice,
      obs: product.obs,
      imageUrl: product.imageUrl,
      amount: product.amount,
      step: product.step,
      extras: product.extras,
      total:
        product.promotionalPrice && product.promotionalPrice > 0
          ? product.promotionalPrice
          : product.price,
    };

    if (!product.extras || product.extras.length <= 0) {
      setLoading(true);
      api()
        .get(`/product/${product.id}/extras`)
        .then((res) => {
          setLoading(false);
          //console.log(res);
          if (res.data === undefined || res.data.code === undefined) {
            toast(res.data, {
              type: "error",
              autoClose: 3000,
            });
            return;
          }
          if (res.data.code !== 200) {
            toast(res.data, {
              type: "error",
              autoClose: 3000,
            });
            return;
          }
          product.extras = res.data.data;
          prod.extras = product.extras;
          //setLocalProduct(prod);
          setModalProduct({
            open: true,
            product: prod,
          });
        })
        .catch((error) => {
          setLoading(false);
          //setState({ status: "404", token: state.token });
          toast(error, {
            type: "error",
            autoClose: 3000,
          });
          return;
        });
    } else {
      //setLocalProduct(prod);
      //setIsOpen(true);
      setModalProduct({
        open: true,
        product: prod,
      });
    }
  }

  function closeModalProduct() {
    //setIsOpen(false);
    setModalProduct({
      open: false,
      product: null,
    });
  }

  function openTaxas() {
    setIsTaxas(true);
  }
  function closeTaxas() {
    setIsTaxas(false);
  }

  //ShowModal - steps
  const [localCartProduct, setLocalCartProduct] =
    React.useState<ICartProduct>();
  const [isSteps, setIsSteps] = React.useState(false);
  function openSteps(cartProduct: ICartProduct) {
    setLocalCartProduct(cartProduct);

    let ids = "";
    let query = "";

    if (cartProduct.flavours && cartProduct.flavours.length > 0) {
      cartProduct.flavours.map((flavour) => {
        if (ids === "") {
          ids = flavour.product_id;
        } else {
          ids += "," + flavour.product_id;
        }
      });

      if (localSize != null && localSize.id != null) {
        query = `?sizeId=${localSize.id}`;
        //console.log(query);
      }
    } else {
      ids = cartProduct.product_id;
    }

    setLoading(true);
    api()
      .get(`/products/${ids}/steps${query}`)
      .then((res) => {
        setLoading(false);
        if (res.data === undefined || res.data.code === undefined) {
          toast(res.data, {
            type: "error",
            autoClose: 3000,
          });
          return;
        }
        if (res.data.code !== 200) {
          toast(res.data, {
            type: "error",
            autoClose: 3000,
          });
          return;
        }
        setSteps(res.data.data);
        setIsSteps(true);
      })
      .catch((error) => {
        setLoading(false);
        toast(error, {
          type: "error",
          autoClose: 3000,
        });
        return;
      });
  }

  function closeSteps() {
    setIsSteps(false);
  }

  function moreStepOption(step: IStep, option: IStepOption) {
    if (step.maximum == 1) {
      if (option.amount == 1) {
        return;
      }
      step.options.map((op) => {
        op.amount = 0;
      });
      option.amount = 1;
      step.selected = 1;
    } else {
      if (option.maximum > 0 && option.amount >= option.maximum) {
        toast("Não é permitido mais desse item", {
          type: "error",
          autoClose: 3000,
        });
        return;
      }

      step.selected = 0;
      step.options.map((op) => {
        step.selected += op.amount;
      });

      if (step.maximum > 0) {
        if (step.selected >= step.maximum) {
          toast("Quantidade máxima excedida para essa etapa", {
            type: "error",
            autoClose: 3000,
          });
          return;
        }
      }

      option.amount += 1;
      step.selected += 1;
    }

    if (step.error === true) {
      if (step.selected >= step.minimum) {
        step.error = false;
      }
    }

    if (step.maximum > 0 && step.selected >= step.maximum) {
      try {
        const key = step.id.replaceAll("step", "");
        if (key !== "") {
          const id = "step" + (Number(key) + 1).toString();
          to(id);
        }
      } catch (error) {
        console.log(error);
      }
    }

    setState({ status: "increment", token: state.token });
  }

  function lessStepOption(step: IStep, option: IStepOption) {
    if (option.amount <= 0) {
      return;
    }

    if (step.minimum === 1 && step.maximum === 1) {
      return;
    }

    option.amount -= 1;

    step.selected = 0;
    step.options.map((op) => {
      step.selected += op.amount;
    });

    setState({ status: "decrement", token: state.token });
  }

  function calcTotalLocalProduct() {
    let totalExtra = 0;
    if (modalProduct.product !== null) {
      if (modalProduct.product.extras) {
        modalProduct.product.extras.map((extra) => {
          if (extra.amount && extra.amount > 0) {
            totalExtra += round2(extra.amount * extra.price);
          }
        });
      }
      modalProduct.product.totalExtra = totalExtra;
      modalProduct.product.total =
        (modalProduct.product.amount || 0) *
        ((modalProduct.product.promotionalPrice &&
        modalProduct.product.promotionalPrice > 0
          ? modalProduct.product.promotionalPrice
          : modalProduct.product.price) +
          (modalProduct.product.totalExtra || 0));

      // arredondar os valores para ficarem corretos no pagamento
      modalProduct.product.total = round2(modalProduct.product.total);
    }
  }

  function moreProduct() {
    //localProduct.amount = (localProduct.amount || 0) + 1;
    modalProduct.product!.amount = (modalProduct.product!.amount || 0) + 1;
    calcTotalLocalProduct();
    setState({
      status: "moreProduct",
      token: state.token,
    });
  }

  function lessProduct() {
    /*if ((localProduct.amount || 0) <= 1) {
      return;
    }

    localProduct.amount = (localProduct.amount || 1) - 1;*/
    if ((modalProduct.product!.amount || 0) <= 1) {
      return;
    }

    modalProduct.product!.amount = (modalProduct.product!.amount || 1) - 1;
    calcTotalLocalProduct();
    setState({ status: "lessProduct", token: state.token });
  }

  function moreExtra(extra: IExtra) {
    if (extra.max > 0 && extra.amount >= extra.max) {
      toast("Não é permitido mais desse item", {
        type: "error",
        autoClose: 3000,
      });
      return;
    }

    extra.amount += 1;
    calcTotalLocalProduct();
    setState({ status: "increment", token: state.token });
  }

  function lessExtra(extra: IExtra) {
    if (extra.amount <= 0) {
      return;
    }

    extra.amount -= 1;
    calcTotalLocalProduct();
    setState({ status: "decrement", token: state.token });
  }

  //ShowModal - size
  const [isSize, setIsSize] = React.useState(false);
  const [localSize, setSize] = React.useState({
    id: "",
    name: "",
    initials: "",
    fractioned: false,
    fractionalMaximum: 0.0,
    selected: 0,
    total: 0.0,
    obs: "",
    groupId: "",
    groupName: "",
  });
  function openSize(size: ISize) {
    size.selected = 0;
    size.total = 0;

    if (company?.status !== "opened") {
      toast("Ops... empresa está fechada no momento", {
        type: "error",
        autoClose: 3000,
      });
      return;
    }

    setSize(size);
    setLoading(true);
    api()
      .get(`/size/${size.id}/products?groupId=${size.groupId}`)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data === undefined || res.data.code === undefined) {
          toast(res.data, {
            type: "error",
            autoClose: 3000,
          });
          return;
        }
        if (res.data.code !== 200) {
          toast(res.data, {
            type: "error",
            autoClose: 3000,
          });
          return;
        }

        localSize.selected = 0;
        localSize.total = 0;
        setProductsSize(res.data.data);

        setIsSize(true);
      })
      .catch((error) => {
        setLoading(false);
        toast(error, {
          type: "error",
          autoClose: 3000,
        });
        return;
      });
  }

  function closeSize() {
    setIsSize(false);
  }

  const refreshStore = (store: any) => {
    cart.styleSale = styleSale ?? store.styleSale;
    sessionStorage.setItem("@menuchef/cart", JSON.stringify(cart));
    sessionStorage.setItem("@menuchef/store", JSON.stringify(store));
    setCompany(store);
    getGroups(store);
  };

  const getGroups = (store: any) => {
    if (store !== undefined && groups === undefined) {
      api()
        .get(`/store/${store.id}/groups/products`)
        .then((res) => {
          //console.log(res);
          if (res.data === undefined || res.data.code === undefined) {
            setState({ status: "404", token: state.token });
          }
          if (res.data.code !== 200) {
            setState({ status: res.data.code, token: state.token });
          }
          setGroups(res.data.data);
        })
        .catch((error) => {
          setState({ status: "404", token: state.token });
        });
    }
  };

  const getStore = () => {
    if (
      state.token !== "" &&
      (company === undefined || company === null) &&
      state.status !== "404"
    ) {
      setLoading(true);
      api()
        .get("/store/suffix/" + suffix)
        .then((res) => {
          if (res.data === undefined || res.data.code === undefined) {
            setState({ status: "404", token: state.token });
          }
          if (res.data.code !== 200) {
            setState({ status: res.data.code, token: state.token });
          }
          refreshStore(res.data.data);
        })
        .catch((error) => {
          setState({ status: "404", token: state.token });
        })
        .finally(() => setLoading(false));
    }
  };

  const getAuthorization = () => {
    if (state.token === "") {
      auth().then((token) => {
        if (token !== undefined && token !== "") {
          state.status = "allowed";
          state.token = token;
          setState({ status: "allowed", token: token });
          getStore();
        }
      });
    }
  };

  useEffect(() => {
    getAuthorization();
  }, []);

  useEffect(() => {
    if (groups) {
      setScrollSticky();
    }
  }, [groups]);

  const myFunction = useCallback(() => {
    const header = document.getElementById("myHeader");
    if (header) {
      const sticky = header.offsetTop;
      if (window.pageYOffset > sticky) {
        header.style.position = "fixed";
        header.style.top = "0";
      } else {
        header.style.position = "inherit";
        header.style.top = "";
      }
    }

    if (!tabForce.current) {
      let index: number | null = null;
      groups?.map((group) => {
        const id = `group${group.id}`;
        const groupHeader = document.getElementById(id);
        if (groupHeader) {
          const pageYOffset = window.pageYOffset;
          const groupSticky = groupHeader.offsetTop;
          if (pageYOffset > groupSticky - 60) {
            const visible = groups.filter((g) => g.id === group.id);
            if (visible.length > 0) {
              index = groups.indexOf(visible[0]);
            }
          }
        }
      });

      if (index && index !== value) {
        setValue(index);
      }
    }
  }, [groups, tabForce, setValue]);

  function setScrollSticky() {
    window.onscroll = function () {
      myFunction();
    };
  }

  function moreProductSize(product: IProduct) {
    if (!localSize.fractioned) {
      localSize.selected = 0;
      productsSize.map((prodSize) => {
        prodSize.amount = 0;
      });
    }

    if (localSize.fractionalMaximum > 0) {
      if (localSize.selected >= localSize.fractionalMaximum) {
        toast("Limite máximo do tamanho atingido", {
          type: "error",
          autoClose: 3000,
        });
        return;
      }
    }

    if ((product.amount || 0) > 0) {
      if ((product.amount || 0) >= localSize.fractionalMaximum) {
        toast("Limite de sabores atingido", {
          type: "error",
          autoClose: 3000,
        });
        return;
      }
    }

    product.amount = (product.amount || 0) + 1;
    localSize.selected += 1;

    localSize.total = 0;
    if (company !== undefined && company !== null) {
      productsSize.map((prod: IProduct) => {
        if ((prod.amount || 0) > 0) {
          const price =
            prod.promotionalPrice && prod.promotionalPrice > 0
              ? prod.promotionalPrice
              : prod.price;
          if ((company?.fractionalPrice || "average") === "average") {
            localSize.total += round2(
              ((prod.amount || 0) * price) / localSize.selected
            );
          } else {
            if (localSize.total < price) {
              localSize.total = price;
            }
          }
        }
      });
    }

    setState({
      status: "more product",
      token: state.token,
    });
  }

  function lessProductSize(product: IProduct) {
    if ((product.amount || 0) <= 0) {
      return;
    }

    product.amount = (product.amount || 0) - 1;
    localSize.selected -= 1;

    localSize.total = 0;
    if (localSize.selected > 0) {
      productsSize.map((prod: IProduct) => {
        if ((prod.amount || 0) > 0) {
          const price =
            prod.promotionalPrice && prod.promotionalPrice > 0
              ? prod.promotionalPrice
              : prod.price;
          if ((company?.fractionalPrice || "average") === "average") {
            localSize.total += round2(
              ((prod.amount || 0) * price) / localSize.selected
            );
          } else {
            if (localSize.total < price) {
              localSize.total = price;
            }
          }
        }
      });
    }

    setState({
      status: "less product",
      token: state.token,
    });
  }

  function openModalFracionado(product: ICartProduct) {
    setModalFracionado({
      open: true,
      product: product,
    });
  }

  function closeModalFracionado() {
    setModalFracionado({
      open: false,
      product: null,
    });
  }

  function addFracionado() {
    const newProduct = modalFracionado.product!;

    cart.products.push(newProduct);
    cart.total += newProduct.total;

    steps.map((step) => {
      if (step.type === "product" && step.selected > 0) {
        step.options.map((option) => {
          if (option.amount > 0) {
            cart.grouping_last += 1;
            cart.product_last += 1;

            const newProduct: ICartProduct = {
              id: cart.product_last.toString(),
              grouping_id: cart.grouping_id,
              product_id: option.id,
              amount: option.amount,
              product: option.name,
              price: option.price,
              imageUrl: option.imageUrl,
              obs: "",
              extras: [],
              totalProduct: option.amount * option.price,
              totalExtra: 0.0,
              total: option.amount * option.price,
            };
            cart.products.push(newProduct);
            cart.total += newProduct.total;
          }
        });
      }
    });

    sessionStorage.setItem("@menuchef/cart", JSON.stringify(cart));

    localSize.selected = 0;
    localSize.total = 0;
    setProductsSize([]);

    toast("Produto adicionado com sucesso", {
      type: "success",
      autoClose: 2000,
    });

    closeSize();
    closeSteps();
    setModalFracionado({
      open: false,
      product: null,
    });
  }

  if (state.status === "404") {
    return (
      <Container>
        <ImageOnChef />
        <Form>
          <FormTitle>Loja não encontrada :(</FormTitle>
          <div>
            Infelizmente não encontramos a loja solicitada. Confira se o link
            foi digitado corretamente ou tente novamente mais tarde.
          </div>
        </Form>
      </Container>
    );
  }

  if (company === undefined || company === null) {
    return (
      <Container>
        <ImageOnChef />
        <Form>
          <FormTitle>Carregando...</FormTitle>
        </Form>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <ImageOnChef id="imgOnChef" />

        <ModalFracionados
          open={modalFracionado.open}
          product={modalFracionado.product!}
          onClose={() => {
            if (localCartProduct && localCartProduct !== null) {
              localCartProduct!.extras = [];
            }
            closeModalFracionado();
          }}
          onExecute={addFracionado}
        />

        <ShowModal
          isOpen={loading}
          //setOpen={setIsOpen}
          //onRequestClose={closeModal}
          title={"Carregando..."}
          label=""
          centered={true}
          size="sm"
          document={
            <LeftContainer>
              <DualRing color="#00796B" />
            </LeftContainer>
          }
          isFooter={false}
        />

        <ShowModal
          isOpen={modalProduct.open}
          //setOpen={setIsOpen}
          onRequestClose={closeModalProduct}
          //title={localProduct.name}
          title={modalProduct.product?.name || "Sem titulo"}
          label=""
          document={
            <div>
              {(modalProduct.product?.imageUrl || "") !== "" ? (
                <div style={imgTop}>
                  <img
                    src={modalProduct.product!.imageUrl}
                    height="200px"
                    style={imgTop}
                  />
                </div>
              ) : (
                <></>
              )}
              <Label>{modalProduct.product?.description}</Label>
              <ContainerPriceAmount>
                <PriceContainer>
                  <SectionModal>Preço:</SectionModal>
                  {getPriceModal(modalProduct.product)}
                </PriceContainer>
              </ContainerPriceAmount>

              {modalProduct.product != null &&
              modalProduct.product.extras !== undefined &&
              modalProduct.product.extras.length > 0 ? (
                <>
                  <ListTitle>Adicionais</ListTitle>
                  {modalProduct.product.extras.map((extra) => (
                    <ContainerButtonList
                      key={extra.id}
                      //onClick={() => openModal(product)}
                    >
                      <ContainerProduct>
                        <GroupContainer>{extra.name}</GroupContainer>
                      </ContainerProduct>
                      <ContainerPrice>
                        <LabelPrice>R$ {round2string(extra.price)}</LabelPrice>
                      </ContainerPrice>
                      <AmountContainer>
                        <ButtonAmountExtra onClick={() => lessExtra(extra)}>
                          -
                        </ButtonAmountExtra>
                        <LabelAmountExtra>{extra.amount}</LabelAmountExtra>
                        <ButtonAmountExtra onClick={() => moreExtra(extra)}>
                          +
                        </ButtonAmountExtra>
                      </AmountContainer>
                    </ContainerButtonList>
                  ))}
                </>
              ) : (
                <></>
              )}
              <InputGroup className="mb-3">
                <InputGroup.Text>Observação</InputGroup.Text>
                <FormControl
                  as="textarea"
                  aria-label="With textarea"
                  placeholder="Ex.: Remover cebola"
                  onChange={(ev) => {
                    if (modalProduct.product !== null) {
                      modalProduct.product.obs = ev.target.value;
                    }
                  }}
                />
              </InputGroup>
            </div>
          }
          footer={
            <>
              <AmountContainerLeft>
                <ButtonAmount onClick={lessProduct}>-</ButtonAmount>
                <LabelAmount>{modalProduct.product?.amount || 0}</LabelAmount>
                <ButtonAmount onClick={moreProduct}>+</ButtonAmount>
              </AmountContainerLeft>
              <ContainerPrice>
                <ButtonAdd
                  onClick={() => {
                    const newExtras: IExtra[] = [];
                    let total_extras = 0;

                    if (modalProduct.product === null) {
                      return;
                    }

                    if (modalProduct.product.extras) {
                      modalProduct.product.extras.map((extra) => {
                        if (extra.amount > 0) {
                          const newExtra: IExtra = {
                            id: extra.id,
                            amount: extra.amount,
                            name: extra.name,
                            price: extra.price,
                            max: extra.max,
                          };
                          total_extras += round2(
                            newExtra.amount * newExtra.price
                          );
                          newExtras.push(newExtra);
                        }
                      });
                    }

                    // GENERATORS
                    cart.grouping_last += 1;
                    cart.product_last += 1;

                    const newProduct: ICartProduct = {
                      id: cart.product_last.toString(),
                      amount: modalProduct.product.amount || 0,
                      extras: newExtras,
                      product_id: modalProduct.product.id,
                      product: modalProduct.product.name,
                      obs: modalProduct.product.obs || "",
                      price:
                        modalProduct.product.promotionalPrice &&
                        modalProduct.product.promotionalPrice > 0
                          ? modalProduct.product.promotionalPrice
                          : modalProduct.product.price,
                      imageUrl: modalProduct.product.imageUrl,
                      grouping_id: cart.grouping_last,
                      totalProduct: 0,
                      totalExtra: total_extras,
                      total: 0,
                    };

                    newProduct.totalProduct = round2(
                      newProduct.amount * newProduct.price
                    );
                    newProduct.total =
                      newProduct.totalProduct +
                      round2(newProduct.amount * newProduct.totalExtra);

                    if (modalProduct.product.step) {
                      openSteps(newProduct);
                    } else {
                      cart.products.push(newProduct);

                      cart.total += newProduct.total;
                      //setCookie("cart", cart);
                      sessionStorage.setItem(
                        "@menuchef/cart",
                        JSON.stringify(cart)
                      );

                      if (modalProduct.product.extras) {
                        modalProduct.product.extras.map((extra) => {
                          if (extra.amount > 0) {
                            extra.amount = 0;
                          }
                        });
                      }

                      toast("Produto adicionado com sucesso", {
                        type: "success",
                        autoClose: 2000,
                      });

                      closeModalProduct();
                      //setLocalProduct(defaultLocalProduct);
                      setState({ status: "new product", token: state.token });
                    }
                  }}
                >
                  Adicionar R$ {(modalProduct.product?.total || 0).toFixed(2)}
                </ButtonAdd>
              </ContainerPrice>
            </>
          }
          onNegativo={closeModalProduct}
          //onPositivo={}
        />

        <ShowModal
          isOpen={isSize}
          onRequestClose={closeSize}
          title={localSize.groupName + " " + localSize.name}
          label={
            "Escolha " +
            (localSize.fractionalMaximum > 1
              ? "até " + localSize.fractionalMaximum + " sabores"
              : "1 sabor")
          }
          document={
            <div>
              <PriceContainer>
                <LeftContainerLeft>
                  <ListTitle>Produtos</ListTitle>
                </LeftContainerLeft>
                <RightContainerRight>
                  <Label>
                    {"Selecionado " +
                      localSize.selected.toString() +
                      " de " +
                      localSize.fractionalMaximum}
                  </Label>
                </RightContainerRight>
              </PriceContainer>
              {productsSize.map((product: IProduct) => (
                <ContainerButtonList
                  key={product.id}
                  //onClick={() => openModal(product)}
                >
                  <ContainerProduct>
                    <GroupContainer>
                      <LabelTitleList>{product.name}</LabelTitleList>
                      <Label>{product.description}</Label>
                      {getPriceLeft(product)}
                    </GroupContainer>
                    {localSize.fractioned ? (
                      <AmountContainer>
                        <ButtonAmountExtra
                          onClick={() => {
                            lessProductSize(product);
                          }}
                        >
                          -
                        </ButtonAmountExtra>
                        <LabelAmountExtra>
                          {(product.amount || 0).toString()}
                        </LabelAmountExtra>
                        <ButtonAmountExtra
                          onClick={() => {
                            moreProductSize(product);
                          }}
                        >
                          +
                        </ButtonAmountExtra>
                      </AmountContainer>
                    ) : (
                      <Checkbox
                        //className={classes.checked}
                        checked={(product?.amount || 0) > 0}
                        color="primary"
                        onChange={() =>
                          (product?.amount || 0) > 0
                            ? lessProductSize(product)
                            : moreProductSize(product)
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    )}
                    {product.imageUrl !== null && product.imageUrl !== "" ? (
                      <img
                        src={product.imageUrl}
                        width="64px"
                        height="64px"
                        style={{
                          borderRadius: "5px",
                          marginLeft: "10px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <ImageDefault64 />
                    )}
                  </ContainerProduct>
                </ContainerButtonList>
              ))}
              <InputGroup className="mb-3">
                <InputGroup.Text>Observação</InputGroup.Text>
                <FormControl
                  as="textarea"
                  aria-label="With textarea"
                  placeholder="Ex.: Remover cebola"
                  onChange={(ev) => {
                    localSize.obs = ev.target.value;
                  }}
                />
              </InputGroup>
            </div>
          }
          onNegativo={() => {
            closeSize();
          }}
          labelPositivo={" Adicionar R$ " + localSize.total.toFixed(2)}
          onPositivo={() => {
            if (localSize.selected <= 0) {
              toast("É necessário selecionar no mínimo 1 item", {
                type: "error",
                autoClose: 2000,
              });
              return;
            }
            // GENERATORS
            cart.grouping_last += 1;
            cart.product_last += 1;

            const newProduct: ICartProduct = {
              id: cart.grouping_last.toString(),
              product_id: "",
              product: "",
              price: 0.0,
              grouping_id: cart.grouping_id,
              sizeId: localSize.id,
              obs: localSize.obs,
              imageUrl: "",
              amount: 1,
              extras: [],
              totalProduct: 0.0,
              totalExtra: 0.0,
              total: 0.0,
            };
            const newProducts: ICartProduct[] = [];
            let existsSteps = false;

            productsSize.map((prod: IProduct) => {
              if ((prod.amount || 0) > 0) {
                if (!existsSteps) {
                  if (prod.step) {
                    existsSteps = prod.step;
                  }
                }

                const newProd: ICartProduct = {
                  id: cart.product_last.toString(),
                  amount: (prod.amount || 0) / localSize.selected,
                  extras: [],
                  product_id: prod.id,
                  product: prod.name,
                  obs: prod.obs || "",
                  price:
                    company.fractionalPrice === "bigger"
                      ? localSize.total
                      : prod.promotionalPrice && prod.promotionalPrice > 0
                      ? prod.promotionalPrice
                      : prod.price,
                  sizeId: localSize.id,
                  imageUrl: prod.imageUrl,
                  grouping_id: cart.grouping_last,
                  totalProduct: 0.0,
                  totalExtra: 0.0,
                  total: 0.0,
                };

                newProd.totalProduct = round2(newProd.amount * newProd.price);
                newProd.total =
                  newProd.totalProduct +
                  round2(newProd.amount * newProd.totalExtra);
                newProducts.push(newProd);

                // product
                let prefix = "";
                if ((prod.amount || 0) > 1) {
                  prefix =
                    (prod.amount || 0).toString() +
                    "/" +
                    localSize.selected +
                    " ";
                }

                if (newProduct.product === "") {
                  newProduct.product = prefix + newProd.product;
                } else {
                  newProduct.product += " | " + prefix + newProd.product;
                }

                if (newProduct.imageUrl == "" && newProd.imageUrl !== "") {
                  newProduct.imageUrl = newProd.imageUrl;
                }

                newProduct.totalProduct += newProd.totalProduct;
                newProduct.totalExtra += newProd.totalExtra;
                newProduct.total += newProd.total;
              }
            });

            newProduct.product =
              localSize.groupName +
              " " +
              localSize.name +
              " " +
              newProduct.product;
            newProduct.flavours = newProducts;

            if (existsSteps) {
              openSteps(newProduct);
            } else {
              openModalFracionado(newProduct);
            }
          }}
        />

        <ShowModal
          isOpen={isSteps}
          //onRequestClose={closeSteps}
          title={"Etapas"}
          label=""
          size="xl"
          centered={false}
          document={
            <div>
              {steps.map((step) => (
                <div id={"step" + step.id}>
                  <ContainerListTitle>
                    <ListTitleContainer>
                      <ListTitle>{step.name}</ListTitle>
                      <Label>
                        Selecione
                        {step.minimum === 1 && step.maximum === 1
                          ? " 1 item"
                          : step.minimum > 0 && step.maximum > 0
                          ? " de " +
                            step.minimum +
                            " até " +
                            step.maximum +
                            " itens"
                          : step.maximum > 0
                          ? " até " + step.maximum
                          : " a vontade"}
                      </Label>
                    </ListTitleContainer>
                    {step.minimum > 0 ? (
                      <ListTitleMoreContainer>
                        <Outline isError={step.error}>Obrigatório</Outline>
                        <Label>
                          {" " + step.selected + " de " + step.maximum}
                        </Label>
                      </ListTitleMoreContainer>
                    ) : (
                      <ListTitleMoreContainer>
                        <Label>
                          {" " + step.selected + " de " + step.maximum}
                        </Label>
                      </ListTitleMoreContainer>
                    )}
                  </ContainerListTitle>
                  {step.options.map((option) => (
                    <ContainerButtonList
                      key={option.id}
                      //onClick={() => moreStepOption(step, option)}
                    >
                      {step.type === "product" &&
                        (option.imageUrl !== null && option.imageUrl !== "" ? (
                          <img
                            src={option.imageUrl}
                            width="84px"
                            height="84px"
                            style={{
                              borderRadius: "5px",
                              marginLeft: "10px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <ImageDefault84 />
                        ))}
                      <ContainerProduct>
                        <GroupContainer>
                          <LabelTitleList>{option.name}</LabelTitleList>
                        </GroupContainer>
                      </ContainerProduct>
                      <ContainerPrice>
                        <LabelPrice>R$ {round2string(option.price)}</LabelPrice>
                      </ContainerPrice>
                      {step.maximum === 1 ? (
                        <Checkbox
                          //className={classes.checked}
                          checked={option.amount > 0}
                          color="primary"
                          onChange={() =>
                            option.amount > 0
                              ? lessStepOption(step, option)
                              : moreStepOption(step, option)
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      ) : (
                        <AmountContainer>
                          <ButtonAmountExtra
                            onClick={() => lessStepOption(step, option)}
                          >
                            -
                          </ButtonAmountExtra>
                          <LabelAmountExtra>{option.amount}</LabelAmountExtra>
                          <ButtonAmountExtra
                            onClick={() => moreStepOption(step, option)}
                          >
                            +
                          </ButtonAmountExtra>
                        </AmountContainer>
                      )}
                    </ContainerButtonList>
                  ))}
                </div>
              ))}
            </div>
          }
          onNegativo={() => {
            localCartProduct!.extras = [];
            closeSteps();
          }}
          onPositivo={() => {
            let errors = false;
            let page = "";
            let msg = "";
            steps.map((step) => {
              if (errors === false) {
                if (step.minimum > 0) {
                  if (step.selected < step.minimum) {
                    msg =
                      "É necessário escolher no mínimo " +
                      step.minimum +
                      " ite" +
                      (step.minimum === 1 ? "m" : "ns") +
                      " da etapa " +
                      step.name;
                    errors = true;
                    page = "step" + step.id;
                    step.error = true;
                  }
                }
              }
            });

            if (errors) {
              if (msg !== "") {
                toast(msg, {
                  type: "error",
                  autoClose: 3000,
                });
              }

              if (page !== "") {
                to(page);
              }

              setState({ status: "error", token: state.token });
            } else {
              let totalSteps = 0;
              steps.map((step) => {
                if (step.selected > 0) {
                  if (step.type === "extra") {
                    step.options.map((option) => {
                      if (option.amount > 0) {
                        const newExtra: IExtra = {
                          id: option.id,
                          amount: option.amount,
                          name: option.name,
                          price: option.price,
                          max: option.maximum,
                          stepId: step.id,
                          stepDescription: step.surname,
                        };
                        localCartProduct!.extras.push(newExtra);
                        totalSteps += round2(newExtra.amount * newExtra.price);
                      }
                    });
                  } else if (step.type === "custom") {
                    step.options.map((option) => {
                      if (option.amount > 0) {
                        if (
                          localCartProduct!.obs === undefined ||
                          localCartProduct!.obs === null ||
                          localCartProduct!.obs === ""
                        ) {
                          localCartProduct!.obs = `${option.amount}X ${option.name}`;
                        } else {
                          localCartProduct!.obs += ` | ${option.amount}X ${option.name}`;
                        }
                      }
                    });
                  }
                }
              });

              if (localCartProduct) {
                if (localCartProduct.flavours) {
                  openModalFracionado(localCartProduct);
                } else {
                  localCartProduct.total += round2(
                    localCartProduct.amount * totalSteps
                  );
                  cart.products.push(localCartProduct);
                  cart.total += localCartProduct.total;

                  steps.map((step) => {
                    if (step.type === "product" && step.selected > 0) {
                      step.options.map((option) => {
                        if (option.amount > 0) {
                          cart.grouping_last += 1;
                          cart.product_last += 1;

                          const newProduct: ICartProduct = {
                            id: cart.product_last.toString(),
                            grouping_id: cart.grouping_id,
                            product_id: option.id,
                            amount: option.amount,
                            product: option.name,
                            price: option.price,
                            imageUrl: option.imageUrl,
                            obs: "",
                            extras: [],
                            totalProduct: option.amount * option.price,
                            totalExtra: 0.0,
                            total: option.amount * option.price,
                          };
                          cart.products.push(newProduct);
                          cart.total += newProduct.total;
                        }
                      });
                    }
                  });

                  sessionStorage.setItem(
                    "@menuchef/cart",
                    JSON.stringify(cart)
                  );

                  //localProduct.extras.map((extra) => {
                  //if (extra.amount > 0) {
                  //extra.amount = 0;
                  //}
                  //});

                  localSize.selected = 0;
                  localSize.total = 0;
                  setProductsSize([]);

                  toast("Produto adicionado com sucesso", {
                    type: "success",
                    autoClose: 2000,
                  });

                  closeSteps();
                  closeSize();
                  closeModalProduct();
                }
              }
            }
          }}
        />

        <ShowModal
          isOpen={isTaxas}
          onRequestClose={closeTaxas}
          title="Taxas de Entrega Variáveis"
          label=""
          document={
            <div>
              {company.districts && company.districts.length > 0
                ? company.districts.map((district: IDistrict) => (
                    <ContainerButtonList
                      key={district.id}
                      //onClick={() => openModal(product)}
                    >
                      <ContainerProduct>
                        <GroupContainer>
                          <LabelTitleList>{district.name}</LabelTitleList>
                          <LabelTitleList>
                            {`R$ ${round2string(district.tax)}`}
                          </LabelTitleList>
                        </GroupContainer>
                      </ContainerProduct>
                    </ContainerButtonList>
                  ))
                : company.distances.map((distance: IDistance) => (
                    <ContainerButtonList
                      key={distance.id}
                      //onClick={() => openModal(product)}
                    >
                      <ContainerProduct>
                        <GroupContainer>
                          <LabelTitleList>{distance.name}</LabelTitleList>
                          <Label>{`Distância de ${(
                            distance.initial / 1000
                          ).toFixed(0)} à ${(distance.final / 1000).toFixed(
                            0
                          )} KM`}</Label>
                          <LabelTitleList>
                            {`R$ ${round2(distance.value)}`}
                          </LabelTitleList>
                        </GroupContainer>
                      </ContainerProduct>
                    </ContainerButtonList>
                  ))}
            </div>
          }
          onNegativo={() => closeTaxas()}
          labelPositivo="OK"
          onPositivo={() => closeTaxas()}
        />

        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <ContainerOpened isActive={company?.status === "opened"}>
            <LabelOpend>
              <ImageCart />
              {company?.status === "opened" ? "Aberto" : "Fechado"}
            </LabelOpend>
          </ContainerOpened>
          <ContainerTitle>
            <LeftContainer>
              {company.imageUrl !== null && company.imageUrl !== "" ? (
                <img
                  src={company?.imageUrl}
                  width="90px"
                  height="90px"
                  style={{
                    borderRadius: "50px",
                    marginLeft: "0px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                ""
              )}
            </LeftContainer>

            <RightContainer>
              <TitleCard>
                {company === undefined ? "Carregando..." : company?.name}
              </TitleCard>

              {tableId ? (
                <div>
                  <TitleCard>Você está na mesa {tableId}</TitleCard>
                </div>
              ) : (
                <>
                  <div>
                    <Label>
                      <ImageMap />
                      {company?.address +
                        ", " +
                        company?.number +
                        " - " +
                        company?.district +
                        " - " +
                        company?.city.name +
                        "/" +
                        company?.city.state}
                    </Label>
                  </div>
                  <div>
                    <Label>
                      <ImagePhone />
                      {`${company?.phone} ${
                        company?.cellPhone ? " / " + company?.cellPhone : ""
                      }`}
                    </Label>
                  </div>
                  <div>
                    <Label>
                      <ImageTime />
                      {`Tempo de Entrega de ${company.initialDeliveryTime} à ${company.finalDeliveryTime} minutos`}
                    </Label>
                  </div>
                  <div>
                    <Label>
                      <ImageDelivery />
                      {company.districts.length > 0 ||
                      company.distances.length > 0 ? (
                        <>
                          Taxa de Entrega Variável
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => openTaxas()}
                          >
                            Ver taxas
                          </Button>
                        </>
                      ) : (company?.tax || 0) === 0 ? (
                        "Taxa de Entrega Gratuita"
                      ) : (
                        `Taxa de Entrega R$ ${company?.tax.toFixed(2)}`
                      )}
                    </Label>
                  </div>
                  {company?.minimumSaleValue !== null &&
                  company?.minimumSaleValue > 0 ? (
                    <div>
                      <Label>
                        <ImageCartStore />
                        {`Preço mínimo de venda ${round2string(
                          company.minimumSaleValue
                        )}`}
                      </Label>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </RightContainer>
          </ContainerTitle>
        </Form>

        {groups === undefined ? (
          <FormTitle>Carregando os grupos e produtos...</FormTitle>
        ) : (
          <FormGroup id="myHeader">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={true}
              aria-label="scrollable auto tabs example"
            >
              {groups.map((group) => (
                <Tab label={group.name} />
              ))}
            </Tabs>
          </FormGroup>
        )}

        <Form>
          {groups === undefined ? (
            <FormTitle>Carregando os grupos e produtos...</FormTitle>
          ) : (
            <div>
              {groups.map((group) => (
                <div id={"group" + group.id}>
                  <ContainerListMain>
                    <ListTitleContainer>
                      <ListTitle>{group.name}</ListTitle>
                    </ListTitleContainer>
                  </ContainerListMain>
                  {group.products.length > 0
                    ? group.products.map((product: IProduct) => (
                        <ContainerButtonList
                          key={product.id}
                          onClick={() => openModal(product)}
                        >
                          <ContainerProduct>
                            <GroupContainer>
                              <LabelTitleList>{product.name}</LabelTitleList>
                              {product.description &&
                              product.description !== "" ? (
                                <Label>{product.description}</Label>
                              ) : (
                                ""
                              )}
                              {getPriceLeft(product)}
                            </GroupContainer>
                            {product.imageUrl !== null &&
                            product.imageUrl !== "" ? (
                              <img
                                src={product.imageUrl}
                                width="84px"
                                height="84px"
                                style={{
                                  borderRadius: "5px",
                                  marginLeft: "10px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <ImageDefault84 />
                            )}
                          </ContainerProduct>
                        </ContainerButtonList>
                      ))
                    : group.sizes?.map((size: ISize) => (
                        <ContainerButtonList
                          key={size.id}
                          onClick={() => {
                            size.groupId = group.id;
                            size.groupName = group.name;
                            openSize(size);
                          }}
                        >
                          <ContainerProduct>
                            <GroupContainer>
                              <LabelTitleList>{size.name}</LabelTitleList>
                              <Label>
                                {"Escolha " +
                                  (size.fractionalMaximum > 1
                                    ? "até " +
                                      size.fractionalMaximum +
                                      " sabores"
                                    : "1 sabor")}
                              </Label>
                            </GroupContainer>
                          </ContainerProduct>
                        </ContainerButtonList>
                      ))}
                </div>
              ))}
            </div>
          )}
        </Form>
      </Container>
      <Footer>
        <FormFooter>
          <ContainerFooter>
            <RightFooter>
              <ImageArrowTop
                onClick={() => {
                  to("imgOnChef");
                }}
              />
            </RightFooter>
            {styleSale !== "menu" && (
              <>
                <CenterFooter
                  onClick={() => {
                    if (styleSale === "menu") {
                      return;
                    }
                    if (company?.status !== "opened") {
                      toast("Ops... empresa está fechada no momento", {
                        type: "error",
                        autoClose: 3000,
                      });
                      return;
                    }

                    if (tableId) {
                      navigate(`/store/${suffix}/table/${tableId}/cart`);
                      /*history.push(
                    match.path.replaceAll(":suffix", suffix).replaceAll(":tableId", tableId) + "/cart"
                  );*/
                    } else {
                      navigate(`/store/${suffix}/cart`);
                      /*history.push(
                    match.path.replaceAll(":suffix", suffix) + "/cart"
                  );*/
                    }
                  }}
                >
                  Total: R$ {round2string(cart.total)}
                </CenterFooter>
                <RightFooter>
                  <ImageCart
                    onClick={() => {
                      if (styleSale === "menu") {
                        return;
                      }
                      if (company?.status !== "opened") {
                        toast("Ops... empresa está fechada no momento", {
                          type: "error",
                          autoClose: 3000,
                        });
                        return;
                      }

                      if (tableId) {
                        navigate(`/store/${suffix}/table/${tableId}/cart`);
                        /*history.push(
                      match.path.replaceAll(":suffix", suffix).replaceAll(":tableId", tableId) + "/cart"
                    );*/
                      } else {
                        navigate(`/store/${suffix}/cart`);
                        /*history.push(
                      match.path.replaceAll(":suffix", suffix) + "/cart"
                    );*/
                      }
                    }}
                  />
                </RightFooter>
              </>
            )}
          </ContainerFooter>
        </FormFooter>
      </Footer>
    </>
  );
}

function getPriceModal(product: IProduct | null) {
  if (product !== null) {
    if (product.promotionalPrice && product.promotionalPrice > 0) {
      return (
        <ContainerPriceModal>
          <PromotionContainerModal>
            <LabelPriceOldModal>
              de R$ {round2string(product.price)}
            </LabelPriceOldModal>
            <LabelPriceModal>
              por R$ {round2string(product.promotionalPrice)}
            </LabelPriceModal>
          </PromotionContainerModal>
        </ContainerPriceModal>
      );
    }
  }
  return (
    <ContainerPriceModal>
      <LabelPriceModal>R$ {round2string(product?.price || 0)}</LabelPriceModal>
    </ContainerPriceModal>
  );
}

function getPriceLeft(product: IProduct) {
  if (product.promotionalPrice && product.promotionalPrice > 0) {
    return (
      <ContainerPriceLeft>
        <LabelPriceOldLeft>
          de R$ {round2string(product.price)}
        </LabelPriceOldLeft>
        <LabelPriceLeft>
          por R$ {round2string(product.promotionalPrice)}
        </LabelPriceLeft>
        <LabelPromotion>Promoção</LabelPromotion>
      </ContainerPriceLeft>
    );
  }
  return (
    <ContainerPriceLeft>
      <LabelPriceLeft>R$ {round2string(product.price)}</LabelPriceLeft>
    </ContainerPriceLeft>
  );
}
