import {
  Container,
  Form,
  FormTitle,
  Section,
  LeftContainer,
  Label,
  ContainerProduct,
  ContainerButtonList,
  LabelPrice,
  GroupContainer,
  LabelTitleList,
  LabelPriceLeft,
  ContainerPriceLeft,
  ContainerOptions,
  ContainerOptionsBlock,
  ButtonAll,
  Description,
  ButtonOutline,
  ImageRemove,
  ImageCartDark,
  LineContainer,
  LineContainerRight,
  LabelTitle,
  CenterContainer,
  ImageDefault84,
  ContainerPriceAmount,
  PriceContainer,
  SectionModal,
  ListTitle,
  ContainerPrice,
  AmountContainer,
  ButtonAmountExtra,
  LabelAmountExtra,
  ButtonAmount,
  LabelAmount,
  ContainerPriceModal,
  LabelPriceModal,
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import ShowModal from "../../components/ShowModal";
import { toast } from "react-toastify";
import {
  ICart,
  ICartProduct,
  IStore,
  ISale,
  ISaleProduct,
  ISaleProductExtra,
  ISaleProductFlavour,
  ISaleProductFlavourExtra,
  IProduct,
  IExtra,
} from "../../classes/interfaces";
import api from "../../classes/api";
import { DualRing } from "react-spinners-css";
import { FormControl, InputGroup } from "react-bootstrap";
import { AmountContainerLeft, ButtonAdd } from "../Store/styles";
import { round2string } from "../../classes/numbers";
import { Button } from "@mui/material";

const imgTop = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  marginBottom: "5px",
};

const defaultLocalProduct = {
  id: "",
  product: "",
  amount: 0,
  price: 0.0,
  obs: "",
  imageUrl: "",
  extras: [{ id: "", name: "", price: 0.0, amount: 0.0, max: 0.0 }],
  total: 0.0,

  product_id: "",
  grouping_id: 0,
  totalProduct: 0.0,
  totalExtra: 0.0,
};

export default function Cart() {
  //const history = useHistory();
  //let match = useRouteMatch();
  const navigate = useNavigate();
  const { suffix, tableId } = useParams<{ suffix: string, tableId: string }>();
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({ status: "create" });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [localProduct, setLocalProduct] =
    React.useState<ICartProduct>(defaultLocalProduct);
  const [extras, setExtras] = React.useState([]);

  const [deleteDialog, setDeleteDialog] = React.useState({
    isOpen: false,
    index: -1,
  });

  const store: IStore = JSON.parse(
    sessionStorage.getItem("@menuchef/store") || "{}"
  );

  function openDeleteProduct(index: number) {
    setDeleteDialog({ isOpen: true, index: index });
  }
  function closeDeleteProduct() {
    setDeleteDialog({ isOpen: false, index: -1 });
  }

  function to(id: string) {
    const element = document.getElementById(id);
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
      });
  }

  /*const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    details: {
      display: "block",
    },
    checked: {
      color: teal[400],
      "&$checked": {
        color: teal[600],
      },
      margin: 0,
      marginLeft: 10,
      padding: 0,
    },
  }));

  const classes = useStyles();*/

  let cart: ICart = {
    grouping_last: 0,
    product_last: 0,
    products: [],
    payments: [],
    total: 0.0,
    styleSale: "menu",
  };

  function calcTotalLocalProduct() {
    let totalExtra = 0;
    if (localProduct.extras) {
      localProduct.extras.map((extra) => {
        if (extra.amount && extra.amount > 0) {
          totalExtra += extra.amount * extra.price;
        }
      });
    }
    localProduct.totalExtra = totalExtra;
    localProduct.total =
      (localProduct.amount || 0) * localProduct.price +
      (localProduct.totalExtra || 0);
  }

  function saveCart() {
    cart.total = 0;
    cart.products.map(prod => {
      cart.total += prod.total;
    });

    sessionStorage.setItem("@menuchef/cart", JSON.stringify(cart));
    setState({ status: 'calc prod' });
  }

  function moreProduct() {
    localProduct.amount = (localProduct.amount || 0) + 1;
    calcTotalLocalProduct();
    setState({
      status: localProduct.product + localProduct.amount.toString(),
    });
  }

  function lessProduct() {
    if ((localProduct.amount || 0) <= 1) {
      return;
    }

    localProduct.amount = (localProduct.amount || 1) - 1;
    calcTotalLocalProduct();
    setState({ status: "decrement" });
  }

  function moreExtra(extra: IExtra) {
    if (extra.max > 0 && extra.amount >= extra.max) {
      toast("Não é permitido mais desse item", {
        type: "error",
        autoClose: 3000,
      });
      return;
    }

    extra.amount += 1;
    calcTotalLocalProduct();
    setState({ status: "increment" });
  }

  function lessExtra(extra: IExtra) {
    if (extra.amount <= 0) {
      return;
    }

    extra.amount -= 1;
    calcTotalLocalProduct();
    setState({ status: "decrement" });
  }

  const sessionCart = sessionStorage.getItem("@menuchef/cart");
  if (sessionCart !== null) {
    cart = JSON.parse(sessionCart);
  }

  if (
    cart === undefined ||
    cart.products === undefined ||
    cart.products.length <= 0
  ) {
    return (
      <Container>
        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <LineContainer>
            <LineContainer>
              <Section>
                <ImageCartDark />
                Meu Carrinho
              </Section>
            </LineContainer>
            <LineContainerRight>
              <Button
                variant="text"
                size="small"
                color="error"
                onClick={() => {
                  sessionStorage.removeItem("@menuchef/cart");
                  setState({ status: "cart clear" });
                }}
              >
                Limpar Carrinho
              </Button>
            </LineContainerRight>
          </LineContainer>
          <CenterContainer>
            <img
              src="../../../media/circulo_mesa_128.png"
              style={{ width: "100px", height: "100px" }}
            />
            <LabelTitleList>Nenhum item no carrinho</LabelTitleList>
          </CenterContainer>
          <ButtonOutline
            onClick={() =>
              navigate(`/store/${suffix}`)
              /*history.push(
                match.path
                  .replaceAll("/cart", "")
                  .replaceAll(":suffix", suffix)
                  .replaceAll("/cart", "")
              )*/
            }
          >
            Adicionar itens
          </ButtonOutline>
        </Form>
      </Container>
    );
  }

  //ShowModal
  function openModal(product: ICartProduct) {
    if ((product.amount || 0) <= 0) {
      product.amount = 1;
    }

    setLoading(true);
    api()
      .get(`/product/${product.product_id}/extras`)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data === undefined || res.data.code === undefined) {
          toast(res.data, {
            type: "error",
            autoClose: 3000,
          });
          return;
        }
        if (res.data.code !== 200) {
          toast(res.data, {
            type: "error",
            autoClose: 3000,
          });
          return;
        }
        const response = res.data.data;

        product.extras.map((extra: IExtra) => {
          response.map((resp: IExtra) => {
            if (extra.id === resp.id) {
              resp.amount = extra.amount;
            }
          });
        });

        setExtras(response);
        setLocalProduct(product);
        setIsOpen(true);
      })
      .catch((error) => {
        setLoading(false);
        //setState({ status: "404", token: state.token });
        toast(error, {
          type: "error",
          autoClose: 3000,
        });
        return;
      });
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Container>

        <ShowModal
          isOpen={loading}
          title={"Carregando..."}
          label=""
          centered={true}
          size="sm"
          document={
            <LeftContainer>
              <DualRing color="#00796B" />
            </LeftContainer>
          }
          isFooter={false}
        />

        <ShowModal
          isOpen={modalIsOpen}
          //setOpen={setIsOpen}
          onRequestClose={closeModal}
          title={localProduct.product}
          label=""
          document={
            <div>
              {localProduct.imageUrl !== null &&
                localProduct.imageUrl !== "" ? (
                <div style={imgTop}>
                  <img
                    src={localProduct.imageUrl}
                    height="200px"
                    style={imgTop}
                  />
                </div>
              ) : (
                ""
              )}
              <ContainerPriceAmount>
                <PriceContainer>
                  <SectionModal>Preço:</SectionModal>
                  {getPriceModal(localProduct)}
                </PriceContainer>
              </ContainerPriceAmount>

              {extras !== undefined &&
                extras.length > 0 ? (
                <>
                  <ListTitle>Adicionais</ListTitle>
                  {extras.map((extra: IExtra) => (
                    <ContainerButtonList
                      key={extra.id}
                    //onClick={() => openModal(product)}
                    >
                      <ContainerProduct>
                        <GroupContainer>{extra.name}</GroupContainer>
                      </ContainerProduct>
                      <ContainerPrice>
                        <LabelPrice>R$ {extra.price.toFixed(2)}</LabelPrice>
                      </ContainerPrice>
                      <AmountContainer>
                        <ButtonAmountExtra onClick={() => lessExtra(extra)}>
                          -
                        </ButtonAmountExtra>
                        <LabelAmountExtra>{extra.amount}</LabelAmountExtra>
                        <ButtonAmountExtra onClick={() => moreExtra(extra)}>
                          +
                        </ButtonAmountExtra>
                      </AmountContainer>
                    </ContainerButtonList>
                  ))}
                </>
              ) : (
                <></>
              )}
              <InputGroup>
                <InputGroup.Text>Observação</InputGroup.Text>
                <FormControl
                  as="textarea"
                  aria-label="With textarea"
                  placeholder="Ex.: Remover cebola"
                  onChange={(ev) => {
                    localProduct.obs = ev.target.value;
                  }}
                />
              </InputGroup>
            </div>
          }
          footer={
            <>
              <AmountContainerLeft>
                <ButtonAmount onClick={lessProduct}>-</ButtonAmount>
                <LabelAmount>{localProduct.amount}</LabelAmount>
                <ButtonAmount onClick={moreProduct}>+</ButtonAmount>
              </AmountContainerLeft>
              <ContainerPrice>
                <ButtonAdd
                  onClick={() => {
                    cart.products.map(prod => {
                      if (prod.id === localProduct.id) {
                        const newExtras: IExtra[] = [];
                        let total_extras = 0;

                        if (extras) {
                          extras.map((extra: IExtra) => {
                            if (extra.amount > 0) {
                              const newExtra: IExtra = {
                                id: extra.id,
                                amount: extra.amount,
                                name: extra.name,
                                price: extra.price,
                                max: extra.max,
                              };
                              total_extras += newExtra.amount * newExtra.price;
                              newExtras.push(newExtra);
                            }
                          });
                        }

                        prod.extras = newExtras;
                        prod.totalExtra = total_extras;
                        prod.amount = localProduct.amount;
                        prod.totalProduct =
                          localProduct.amount * localProduct.price;
                        prod.total =
                          prod.totalProduct +
                          localProduct.amount * localProduct.totalExtra;

                        saveCart();

                        if (localProduct.extras) {
                          localProduct.extras.map((extra) => {
                            if (extra.amount > 0) {
                              extra.amount = 0;
                            }
                          });
                        }

                        toast("Produto alterado", {
                          type: "success",
                          autoClose: 2000,
                        });
                      }
                    });

                    closeModal();
                  }}
                >
                  Alterar R$ {(localProduct.total || 0).toFixed(2)}
                </ButtonAdd>
              </ContainerPrice>
            </>
          }
          onNegativo={closeModal}
        //onPositivo={}
        />

        <ShowModal
          isOpen={deleteDialog.isOpen}
          onRequestClose={closeDeleteProduct}
          title="Remover Produto"
          label="Deseja realmente remover o produto ?"
          onNegativo={closeDeleteProduct}
          labelPositivo="Remover"
          onPositivo={() => {
            if (deleteDialog.index !== -1) {
              cart.products.splice(deleteDialog.index, 1);
            }

            let total = 0;
            cart.products.map((product: ICartProduct) => {
              total += product.total;
            });
            cart.total = total;
            if (cart.total <= 0) {
              cart.grouping_last = 0;
              cart.product_last = 0;
            }

            sessionStorage.setItem("@menuchef/cart", JSON.stringify(cart));
            closeDeleteProduct();
            setState({ status: "remove product" });
          }}
        />

        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <LineContainer>
            <LineContainer>
              <Section>
                <ImageCartDark />
                Meu Carrinho
              </Section>
            </LineContainer>
            <LineContainerRight>
              <Button
                variant="text"
                size="small"
                color="error"
                onClick={() => {
                  sessionStorage.removeItem("@menuchef/cart");
                  setState({ status: "cart clear" });
                }}
              >
                Limpar Carrinho
              </Button>
            </LineContainerRight>
          </LineContainer>

          <div /*className={classes.root}*/>
            {cart.products.map((product: ICartProduct) => (
              <ContainerButtonList
                key={product.id}
              //onClick={() => openModal(product)}
              >
                <ContainerProduct>
                  <FormTitle>{product.amount}</FormTitle>
                  <GroupContainer>
                    <LabelTitleList>{product.product}</LabelTitleList>
                    <LeftContainer>
                      {getAdicionais(product)}
                      {product.obs && product.obs !== "" ? (
                        <Label>{"Observação: " + product.obs}</Label>
                      ) : (
                        ""
                      )}
                    </LeftContainer>
                    <ContainerPriceLeft>
                      <LabelPriceLeft>
                        R$ {product.total.toFixed(2)}
                      </LabelPriceLeft>
                    </ContainerPriceLeft>
                  </GroupContainer>
                  {product.imageUrl !== null && product.imageUrl !== "" ? (
                    <img
                      src={product.imageUrl}
                      width="84px"
                      height="84px"
                      style={{
                        borderRadius: "5px",
                        marginLeft: "10px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <ImageDefault84 />
                  )}
                  <ImageRemove
                    onClick={() => {
                      const index = cart.products.indexOf(product);
                      openDeleteProduct(index);
                    }}
                  />
                </ContainerProduct>
              </ContainerButtonList>
            ))}
          </div>
          <ContainerOptionsBlock>
            <ContainerOptions>
              <Description>Total do pedido: </Description>
              <LabelPrice>R$ {cart.total.toFixed(2)}</LabelPrice>
            </ContainerOptions>
          </ContainerOptionsBlock>
          <ButtonOutline
            onClick={() => {
              navigate(`/store/${suffix}`)
              /*history.push(
                match.path
                  .replaceAll("/cart", "")
                  .replaceAll(":suffix", suffix)
                  .replaceAll(":tableId", tableId)
              );*/
            }}
          >
            Adicionar mais itens
          </ButtonOutline>
          {cart.styleSale !== "menu" ? (
            state.status !== "enviando" ? (
              <ButtonAll
                onClick={() => {
                  if (tableId) {
                    const sale: ISale = {
                      store: {
                        id: store.id,
                      },
                      document: Number(tableId),
                      tax: 0,
                      taxPayerIdentificationNumber: "",
                      deliveryName: "",
                      deliveryType: "table",
                      deliveryAddress: "",
                      deliveryNumber: "",
                      deliveryDistrict: "",
                      deliveryComplement: "",
                      deliveryReference: "",
                      deliveryZipCode: "",
                      phone: "",
                      note: "",
                      products: [],
                      payments: [],
                    };

                    cart.products.map((product) => {
                      const newProduct: ISaleProduct = {
                        amount: product.amount,
                        price: product.price,
                        discount: 0,
                        addition: 0,
                        extras: [],
                        flavours: [],
                        note: (product.obs || "").trim(),
                      };

                      if (product.sizeId && product.sizeId !== null) {
                        newProduct.size = {
                          id: product.sizeId,
                        };
                      } else {
                        newProduct.product = {
                          id: product.product_id,
                        };
                      }

                      product.extras.map((extra) => {
                        const newExtra: ISaleProductExtra = {
                          extra: {
                            id: extra.id,
                          },
                          amount: extra.amount,
                          price: extra.price,
                        };

                        if (extra.stepId !== undefined) {
                          newExtra.step = { id: extra.stepId };
                        }

                        newProduct.extras.push(newExtra);
                      });

                      if (product.flavours) {
                        product.flavours.map((flavour) => {
                          const newFlavour: ISaleProductFlavour = {
                            product: {
                              id: flavour.product_id,
                            },
                            amount: flavour.amount,
                            price: flavour.price,
                            addition: 0.0,
                            discount: 0.0,
                            note: flavour.obs,
                            extras: [],
                          };

                          flavour.extras.map((extra) => {
                            const newExtra: ISaleProductFlavourExtra = {
                              extra: {
                                id: extra.id,
                              },
                              amount: extra.amount,
                              price: extra.price,
                            };

                            newFlavour.extras.push(newExtra);
                          });

                          newProduct.flavours.push(newFlavour);
                        });
                      }

                      sale.products.push(newProduct);
                    });

                    let valuePaymentMoney = 0;
                    cart.payments.map((payment) => {
                      if (payment.money) {
                        valuePaymentMoney = payment.value;
                      }
                    });

                    // validate
                    if (sale.products.length <= 0) {
                      toast("Nenhum produto encontrado no carrinho.", {
                        type: "error",
                        autoClose: 2000,
                      });
                      return;
                    }

                    setState({ status: "enviando" });
                    api()
                      .post("/sale", sale)
                      .then((res) => {
                        //console.log(res);
                        if (
                          res.data === undefined ||
                          res.data.code === undefined
                        ) {
                          toast(
                            "Erro inesperado. Tente novamente mais tarde!",
                            {
                              type: "error",
                              autoClose: 2000,
                            }
                          );
                          return;
                        }
                        if (res.data.code !== 200) {
                          if (res.data.status === "store closed") {
                            toast("Desculpe, a loja está fechada no momento.", {
                              type: "error",
                              autoClose: 2000,
                            });
                          }
                          return;
                        }
                        const saleId = res.data.data.sale.id;
                        sessionStorage.removeItem("@menuchef/cart");
                        toast(
                          "Seu pedido está com o estabelecimento, em instantes será produzido.",
                          {
                            type: "success",
                            autoClose: 2000,
                            onClose: () => {
                              if (tableId && tableId !== "") {
                                navigate(`/store/${suffix}/table/${tableId}`)
                              } else {
                                navigate(`/store/${suffix}`)
                              }
                              /*history.push(
                                match.path
                                  .replaceAll("/cart", "")
                                  .replaceAll(":suffix", suffix)
                                  .replaceAll(":tableId", tableId)
                              );*/
                            },
                          }
                        );
                      })
                      .catch((error) => {
                        console.log(error.response);
                        toast("Erro no servidor. Tente novamente mais tarde!", {
                          type: "error",
                          autoClose: 2000,
                        });
                      });
                  } else {
                    if (store.minimumSaleValue !== null && store.minimumSaleValue > 0) {
                      if (cart.total < store.minimumSaleValue) {
                        toast(`Não aceitamos pedidos abaixo de ${round2string(store.minimumSaleValue)}`, {
                          type: "warning",
                          autoClose: 2000,
                        });
                        return;
                      }
                    }

                    navigate(`/store/${suffix}/payments`)
                    /*history.push(
                      match.path
                        .replaceAll("/cart", "")
                        .replaceAll(":suffix", suffix) + "/payments"
                    );*/
                  }
                }}
              >
                Finalizar Compra
              </ButtonAll>
            ) : (
              <LeftContainer>
                <DualRing color="#00796B" />
              </LeftContainer>
            )
          ) : (
            <></>
          )}
        </Form>
      </Container>
    </>
  );
}

function getAdicionais(product: ICartProduct) {
  let stepId = -1;
  let prefix = "";
  return product.extras.map((extra) => {
    if (stepId != extra.stepId) {
      stepId = extra.stepId;
      prefix = extra.stepDescription || "Adicionar";
      prefix += ":";
      return (
        <>
          <LabelTitle>{prefix}</LabelTitle>
          {product.extras.map((ex) => {
            if (ex.stepId === stepId) {
              return <Label>{ex.amount.toString() + "X " + ex.name}</Label>;
            }
          })}
        </>
      );
    }
  });
}

function getPriceModal(product: ICartProduct) {
  return (
    <ContainerPriceModal>
      <LabelPriceModal>R$ {product.price.toFixed(2)}</LabelPriceModal>
    </ContainerPriceModal>
  );
}