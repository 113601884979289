import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Label } from "./styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";

interface ShowModalProps {
  isOpen: boolean;
  //image_url?: string;
  title: string;
  label: string;
  document?: JSX.Element;
  footer?: JSX.Element;
  labelPositivo?: string;
  onPositivo?: React.MouseEventHandler<HTMLButtonElement>;
  labelNeutro?: string;
  onNeutro?: React.MouseEventHandler<HTMLButtonElement>;
  labelNegativo?: string;
  onNegativo?: React.MouseEventHandler<HTMLButtonElement>;
  onRequestClose?(event: React.MouseEvent | React.KeyboardEvent): void;
  centered?: boolean;
  size?: "sm" | "lg" | "xl";
  isFooter?: boolean;
}

export default function ShowModal(props: ShowModalProps) {
  return (
    <Dialog
      fullWidth
      open={props.isOpen}
      //onHide={props.onRequestClose}
      maxWidth={props.size !== undefined ? props.size : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      //centered={props.centered ?? true}
      //scrollable={true}
    >
      <DialogTitle>
        <Stack
          direction="row"
          spacing={1}
          alignItems="start"
          justifyContent="space-between"
          width="100%"
        >
          {props.title}
          {props.onRequestClose && (
            <IconButton size="small" onClick={props.onRequestClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.label !== "" ? <Label>{props.label}</Label> : ""}
          {props.document}
        </DialogContentText>
      </DialogContent>
      {props.isFooter === undefined || props.isFooter ? (
        <DialogActions>
          {props.footer !== undefined ? (
            <>{props.footer}</>
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={props.onNegativo}
              >
                {props.labelNegativo === undefined
                  ? "Cancelar"
                  : props.labelNegativo}
              </Button>
              {props.labelNeutro !== undefined &&
              props.onNeutro !== undefined ? (
                <Button variant="text" onClick={props.onNeutro}>
                  {props.labelNeutro}
                </Button>
              ) : (
                ""
              )}
              {props.onPositivo !== undefined ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.onPositivo}
                >
                  {props.labelPositivo === undefined
                    ? "Salvar"
                    : props.labelPositivo}
                </Button>
              ) : (
                ""
              )}
            </>
          )}
        </DialogActions>
      ) : (
        <></>
      )}
    </Dialog>
  );
}
