import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  font-family: "Work Sans";
`;

export const Label = styled.label`
  font-family: "Work Sans";
`;

export const InputStyled = styled.input`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text};
  font-size: 18px;
  font-family: "Work Sans";

  border: none;
  border-radius: 8px;

  height: 36px;
  padding: 10px;
`;
