import axios from "axios";

//export const apiUrl = "http://192.168.1.29:3000";
export const apiUrl = "https://www.menuchef.com.br/api";

export default function api() {
  let token = sessionStorage.getItem("@menuchef/token") || "";

  const authorization = {
    authorization: `Bearer ${token}`,
  };

  return axios.create({
    baseURL: apiUrl,
    headers: authorization,
  });
}
