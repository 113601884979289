import axios from "axios";
import { apiUrl } from "./api";

const authMaster = "bWVudWNoZWY6MjIwMzE0OGE2MjQxZDNjYjNiMjI4NTE1ZjhhMmU5MWM=";

const token = async () => {
  //console.log("token");
  let bearer: string = "";
  try {
    const config = {
      authorization: `Basic ${authMaster}`,
    };
    const api = axios.create({
      baseURL: apiUrl,
      headers: config,
    });
    const response = await api.post("/auth");
    //console.log(response);
    if (
      response === null ||
      response.data === null ||
      response.data.code !== 200
    ) {
      return "";
    }

    bearer = response.data.data.token;
    await sessionStorage.setItem("@menuchef/token", bearer);
    return bearer;
  } catch (error) {
    console.log(error);
    return "";
  } finally {
    return bearer;
  }
};

export const auth = async () => {
  //console.log("auth");
  try {
    let bearer = sessionStorage.getItem("@menuchef/token") || "";
    //console.log(bearer);
    if (bearer === undefined || bearer === "") {
      bearer = await token();
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      /*const { isExpired } = useJwt(bearer);
      console.log(`isExpired(${isExpired})`);
      if (isExpired) {
        bearer = await token();
      }*/
    }
    //console.log(bearer);
    return bearer;
  } catch (error) {
    console.log(error);
    return "";
  }
};
