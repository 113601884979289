import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.background};
  padding-bottom: 50px;
`;

export const ContainerOpened = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isActive ? props.theme.secondary : props.theme.cancel};
  padding: 5px;
  margin-bottom: 20px;
`;

export const Form = styled.div`
  width: 50vw;
  background-color: ${(props) => props.theme.white};
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;

  @media (max-width: 1024px) {
    width: 98vw;
    padding: 5px;
  }
`;

export const FormGroup = styled.div`
  width: 50vw;
  background-color: ${(props) => props.theme.white};
  padding: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 8px;

  @media (max-width: 1024px) {
    width: 100vw;
    padding: 2px;
  }
`;

export const FormTitle = styled.h2`
  color: ${(props) => props.theme.black};
  font-size: 32px;
  font-family: "Work Sans";
  font-weight: 600;

  padding-bottom: 10px;
`;

export const TitleCard = styled.h2`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${(props) => props.theme.black};
  font-size: 24px;
  font-family: "Work Sans";
  font-weight: 600;

  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Section = styled.p`
  color: ${(props) => props.theme.black};
  font-size: 20px;
  font-family: "Work Sans";
  font-weight: 600;

  padding-bottom: 30px;
  padding-top: 30px;
`;

export const SectionModal = styled.p`
  color: ${(props) => props.theme.text};
  font-size: 18px;
  font-family: "Work Sans";
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 5px;
`;

export const LabelPrice = styled.p`
  color: ${(props) => props.theme.text};

  font-size: 15px;
  font-weight: 700;
  font-family: "Work Sans";

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LabelPriceLeft = styled.p`
  color: ${(props) => props.theme.text};
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 700;
`;

export const LabelPromotion = styled.div`
  color: ${(props) => props.theme.cancel};
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 500;

  display: block;
  margin-top: 5px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100px;

  border: 2px solid red;
  border-radius: 20px;
`;

export const LabelTitleList = styled.p`
  color: ${(props) => props.theme.text};
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 700;

  margin-bottom: 5px;
`;

export const LabelPriceModal = styled.p`
  color: ${(props) => props.theme.text};
  font-size: 20px;
  font-family: "Work Sans";
  font-weight: 600;

  display: flex;
  flex-direction: row;
  justify-content: flex-center;
  align-items: flex-center;
`;

export const LabelPriceOld = styled.p`
  font-size: 10px;
  text-decoration: line-through;
  font-style: italic;
  font-family: "Work Sans";

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LabelPriceOldLeft = styled.p`
  font-size: 10px;
  text-decoration: line-through;
  font-style: italic;
  font-family: "Work Sans";
`;

export const LabelPriceOldModal = styled.p`
  font-size: 14px;
  font-family: "Work Sans";
  text-decoration: line-through;
  font-style: italic;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
`;

export const Label = styled.label`
  color: ${(props) => props.theme.label};
  font-size: 13px;
  font-family: "Work Sans";
  font-weight: 500;

  line-height: 1.5;
  margin-bottom: 5px;
`;

export const LabelOpend = styled.label`
  font-size: 18px;
  color: ${(props) => props.theme.white};
`;

export const GroupContainer = styled.div`
  display: block;
  width: 100%;
  margin-left: 10px;

  font-size: 15px;
  font-family: "Work Sans";
`;

export const PromotionContainer = styled.div`
  display: block;
  margin-left: 10px;
  justify-content: center;
  align-items: flex-end;
`;

export const PromotionContainerModal = styled.div`
  display: block;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
`;

export const GroupBox = styled.div<{ isActive: boolean }>`
  background-color: ${(props) =>
    props.isActive ? props.theme.white : props.theme.background};

  border: ${(props) =>
    props.isActive ? `2px solid ${props.theme.background}` : "none"};

  border-radius: 8px;
  height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 20px;

  margin: 10px;

  cursor: pointer;
`;

export const CategoryImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const ButtonContainer = styled.div`
  text-align: right;
  padding-top: 10px;
  font-family: "Work Sans";
`;

export const Button = styled.button`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;
  font-family: "Work Sans";

  &:hover {
    background-color: ${(props) => props.theme.primary}99;
  }
`;

export const ButtonSecondary = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;
  font-family: "Work Sans";

  position: relative;
  padding-left: 20px;
  padding-right: 20px;

  margin: 10px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ButtonCancel = styled.button`
  background-color: ${(props) => props.theme.cancel};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;
  font-family: "Work Sans";

  position: relative;
  padding-left: 20px;
  padding-right: 20px;

  margin: 10px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 0.5px solid;
  border-color: ${(props) => props.theme.background};
  padding: 5px;
  margin-bottom: 5px;
  font-family: "Work Sans";

  box-shadow: 2px 2px 5px ${(props) => props.theme.background};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerButtonList = styled.div`
  display: flex;

  border-top: 1px solid ${(props) => props.theme.background};
  padding-top: 20px;
  padding-bottom: 20px;

  min-height: 100px;

  cursor: pointer;
`;

export const ImagePreview = styled.img.attrs(() => ({
  src: "../../media/preview.png",
}))`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const ImageDefault64 = styled.img.attrs(() => ({
  //src: "home-image.svg",
  src: "../../media/default.jpg",
}))`
  width: 64px;
  height: 64px;
  border-radius: 5px;
  margin-left: 10px;
  object-fit: cover;
`;

export const ImageDefault84 = styled.img.attrs(() => ({
  //src: "home-image.svg",
  src: "../../media/default.jpg",
}))`
  width: 84px;
  height: 84px;
  border-radius: 5px;
  margin-left: 10px;
  object-fit: cover;
`;

export const ImageNext = styled.img.attrs(() => ({
  //src: "home-image.svg",
  src: "../../media/next.png",
}))`
  width: 32px;
  height: 32px;
  margin-left: 10px;
`;

export const ImageOnChef = styled.img.attrs(() => ({
  //src: "home-image.svg",
  src: "../../logo_padrao.png",
}))`
  width: 90px;
  margin-top: 16px;
`;

export const Image = styled.img.attrs(() => ({
  src: "../../company/italia-no-box.jpg",
}))`
  width: 60%;
`;

export const ImageMap = styled.img.attrs(() => ({
  src: "../../media/map.png",
}))`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export const ImagePhone = styled.img.attrs(() => ({
  src: "../../media/phone.png",
}))`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export const ImageDelivery = styled.img.attrs(() => ({
  src: "../../media/delivery.png",
}))`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export const ImageTime = styled.img.attrs(() => ({
  src: "../../media/time.png",
}))`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export const ImageCart = styled.img.attrs(() => ({
  src: "../../media/cart_white.png",
}))`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const ImageCartStore = styled.img.attrs(() => ({
  src: "../../media/cart.png",
}))`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export const ImageArrowTop = styled.img.attrs(() => ({
  src: "../../media/arrow_top_white.png",
}))`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const ImageProduct = styled.img.attrs(() => ({
  src: "../../product/product.jpg",
}))`
  width: 48px;
  height: 48px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LeftContainerLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

export const RightContainer = styled.div`
  flex: 2;
  display: block;
  justify-content: left;
  align-items: center;
`;

export const RightContainerRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ContainerProduct = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const ContainerPrice = styled.div`
  flex: 2;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
`;

export const ContainerPriceLeft = styled.div`
  margin-top: 5px;
`;

export const ContainerPriceModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonRounded = styled.div`
  border-width: 1,
  border-color: rgba(0,0,0,0.2),
  align-items: center,
  justify-content: center,
  width: 100px,
  height: 100px,
  background-color: #fff,
  border-radius: 50px,

  cursor: pointer;
`;

export const ContainerPriceAmount = styled.div`
  display: flex;
  width: 100%;

  margin-bottom: 10px;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const AmountContainerLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const LabelAmount = styled.div`
  display: flex;

  font-size: 22px;
  font-family: "Work Sans";

  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  justify-content: center;
`;

export const ButtonAmount = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 5px;

  font-size: 14px;
  font-family: "Work Sans";
  font-weight: 600;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ButtonAdd = styled.button`
  background-color: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.white};
  height: 40px;
  width: 100%;

  font-size: 14px;
  font-family: "Work Sans";
  font-weight: 600;

  border: none;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ButtonGroup = styled.button<{ isActive: boolean }>`
  background-color: ${(props) => props.isActive ? props.theme.primary : props.theme.button};
  color: ${(props) => props.theme.white};
  height: 40px;

  font-size: 12px;
  font-family: "Work Sans";
  font-weight: 500;

  border: none;
  border-radius: 5px;
  padding-left: 20px;
  padding: 10px;
  margin: 4px;

  &:hover {
    filter: opacity(0.9);
  }
`;

// List
export const LabelAmountExtra = styled.div`
  font-size: 16px;
  font-family: "Work Sans";

  padding-left: 8px;
  padding-right: 8px;
  font-weight: 600;
`;

export const ButtonAmountExtra = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 28px;
  width: 28px;
  border: none;
  border-radius: 5px;

  font-size: 14px;
  font-family: "Work Sans";
  font-weight: 600;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ListTitle = styled.h2`
  font-size: 14px;
  font-weight: 700;
  font-family: "Work Sans";
  padding-top: 12px;
  padding-bottom: 10px;
`;

export const Outline = styled.div<{ isError: boolean }>`
  font-size: 12px;
  font-weight: 600;
  font-family: "Work Sans";

  border: 2px solid
    ${(props) => (props.isError ? props.theme.cancel : props.theme.button)};
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${(props) =>
    props.isError ? props.theme.cancel : props.theme.button};

  margin-top: 5px;
  margin-bottom: 8px;
`;

export const RadioOutline = styled.div`
  border: 2px solid ${(props) => props.theme.button};
  border-radius: 5px;
  padding: 10px;
  color: ${(props) => props.theme.button};
  width: 20px;
  height: 20px;
`;

export const RadioChecked = styled.div`
  border: 2px solid ${(props) => props.theme.primary};
  border-radius: 5px;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.button};

  width: 20px;
  height: 20px;

  padding: 10px;
`;

export const ContainerListMain = styled.div`
  display: flex;
  width: 97%;
  background-color: ${(props) => props.theme.background};
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ContainerListTitle = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.background};
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ListTitleContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

export const ListTitleMoreContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.button};
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const FormFooter = styled.div`
  width: 50vw;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 90vw;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 600;
`;

export const CenterFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  font-size: 18px;
  font-family: "Work Sans";
  font-weight: 700;
  padding-right: 10px;
`;

export const RightFooter = styled.div`
  display: flex;
  flex: 0;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  font-size: 12px;
  font-family: "Work Sans";
  font-weight: 500;
`;

export const Tag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  border-radius: 30px;

  padding-left: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  margin-right: 20px;
`;
