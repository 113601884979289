import styled from "styled-components";

export const Form = styled.form`
  width: 50vw;
  background-color: ${(props) => props.theme.white};
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 10px;

  border-radius: 8px;
  border: 0.5px solid;
  border-color: ${(props) => props.theme.shadow};
  box-shadow: 2px 2px 5px ${(props) => props.theme.shadow};

  @media (max-width: 1024px) {
    width: 95vw;
    padding: 10px;
  }
`;

export const Section = styled.p`
  color: ${(props) => props.theme.black};
  font-size: 20px;
  font-family: "Work Sans";
  font-weight: 700;

  padding-bottom: 20px;
  padding-top: 10px;
`;

export const ImageFila = styled.img.attrs(() => ({
  src: "../../payment/fila.png",
}))`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.background};
  padding-bottom: 50px;
  height: 100vh;
`;
/*
export const Container = styled.div`
  background-color: ${(props) => props.theme.background};
  width: 100%;
  height: 100vw;
`;
*/
export const ContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
`;

export const ListTitle = styled.h2`
  font-size: 18px;
  font-family: "Work Sans";
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 10px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-family: "Work Sans";
  padding-bottom: 50px;
  text-align: center;

  max-width: 500px;
`;

export const SubTitle = styled.p`
  font-size: 24px;
  padding-bottom: 50px;
  font-family: "Work Sans";
  text-align: center;
  max-width: 500px;
`;

export const ButtonBox = styled.div`
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.white};
  height: 50px;
  width: 40px;

  font-size: 30px;

  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;

  position: relative;
  padding-left: 50px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ButtonSecondary = styled.button`
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;

  position: relative;
  padding-left: 50px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ButtonCancel = styled.button`
  background-color: ${(props) => props.theme.cancel};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;

  position: relative;
  padding-left: 50px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img.attrs(() => ({
  //src: "home-image.svg",
  src: "logo_padrao.png",
}))`
  width: 50%;
`;

export const ButtonAll = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-top: 10px;

  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.theme.button}99;
  }
`;
