import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.background};
  font-family: "Work Sans";
`;

export const ContainerOpened = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.secondary};
  padding: 5px;
  margin-bottom: 20px;
  font-family: "Work Sans";
`;

export const Form = styled.form`
  width: 40vw;
  background-color: ${(props) => props.theme.white};
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 8px;

  @media (max-width: 1024px) {
    width: 90vw;
  }
`;

export const FormTitle = styled.h2`
  color: ${(props) => props.theme.text};

  font-size: 20px;
  font-family: "Work Sans";
  font-weight: 700;
`;

export const Section = styled.p`
  color: ${(props) => props.theme.primary};
  font-size: 20px;
  font-family: "Work Sans";

  padding-bottom: 30px;
  padding-top: 30px;
  font-weight: 700;
`;

export const Label = styled.label`
  font-size: 14px;
  font-family: "Work Sans";
`;

export const LabelOpend = styled.label`
  font-size: 18px;
  font-family: "Work Sans";
  color: ${(props) => props.theme.white};
`;

export const GroupContainer = styled.div`
  display: block;
  width: 100%;
`;

export const GroupBox = styled.div<{ isActive: boolean }>`
  background-color: ${(props) =>
    props.isActive ? props.theme.white : props.theme.background};

  border: ${(props) =>
    props.isActive ? `2px solid ${props.theme.background}` : "none"};

  border-radius: 8px;
  height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 20px;

  margin: 10px;

  cursor: pointer;
`;

export const CategoryImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const ButtonContainer = styled.div`
  text-align: right;
  padding-top: 10px;
`;

export const Button = styled.button`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => props.theme.primary}99;
  }
`;

export const ButtonSecondary = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;

  position: relative;
  padding-left: 20px;
  padding-right: 20px;

  margin: 10px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ButtonCancel = styled.button`
  background-color: ${(props) => props.theme.cancel};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;

  position: relative;
  padding-left: 20px;
  padding-right: 20px;

  margin: 10px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ContainerTitle = styled.div`
  display: flex;

  border-radius: 10px;
  border: 0.5px solid;
  border-color: ${(props) => props.theme.background};
  padding: 10px;
  margin-bottom: 5px;

  box-shadow: 2px 2px 5px ${(props) => props.theme.background};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerButtonList = styled.div`
  display: block;

  border-top: 1px solid ${(props) => props.theme.background};
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 16px;
  padding-bottom: 16px;

  cursor: pointer;
`;

export const ImageOnChef = styled.img.attrs(() => ({
  //src: "home-image.svg",
  src: "../../logo_padrao.png",
}))`
  width: 90px;
  margin-top: 16px;
`;

export const Image = styled.img.attrs(() => ({
  src: "../../company/italia-no-box.jpg",
}))`
  width: 60%;
`;

export const ImageMap = styled.img.attrs(() => ({
  src: "../../media/map.png",
}))`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const ImagePhone = styled.img.attrs(() => ({
  src: "../../media/phone.png",
}))`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const ImageCart = styled.img.attrs(() => ({
  src: "../../media/cart_white.png",
}))`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const ImageProduct = styled.img.attrs(() => ({
  src: "../../product/product.jpg",
}))`
  width: 48px;
  height: 48px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RightContainer = styled.div`
  flex: 2;
  display: block;
  justify-content: left;
  align-items: center;
`;