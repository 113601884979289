import React, { Component, useEffect } from "react";
import {
  Container,
  Title,
  SubTitle,
  Form,
  Section,
  ImageFila,
  ContainerCenter,
  ButtonAll,
} from "./styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ShowModal from "../../components/ShowModal";
import { ISale } from "../../classes/interfaces";
import api from "../../classes/api";
import { auth } from "../../classes/auth";
import { ImageCartDark, LineContainer } from "../Order/styles";

export default function SaleTerminatePage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { saleId } = useParams<{ saleId: string }>();
  const [state, setState] = React.useState({ status: "create", token: "" });
  const [sale, setSale] = React.useState<ISale>();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const getSale = (forcarCarregar: boolean = false) => {
    if (!sale || sale.products.length <= 0 || forcarCarregar) {
      api()
        .get(`/sale/${saleId}`)
        .then((res) => {
          //console.log(res);
          if (res.data === undefined || res.data.code === undefined) {
            setState({ status: "404", token: state.token });
          }
          if (res.data.code !== 200) {
            setState({ status: res.data.code, token: state.token });
          }
          //console.log(res.data.data);
          setSale(res.data.data);
          if (res.data.data) {
            if ((res.data.data.store.cellPhone || "") !== "") {
              openModal();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setState({ status: "404", token: state.token });
        });
    }
  };

  const getAuthorization = () => {
    if (state.token === "") {
      auth().then((token) => {
        if (token !== undefined && token !== "") {
          state.status = "allowed";
          state.token = token;
          setState({ status: "allowed", token: token });
          getSale();
        }
      });
    } else {
      getSale();
    }
  };

  useEffect(() => {
    getAuthorization();
  }, []);

  if (
    sale === undefined ||
    sale.products === undefined ||
    sale.products.length <= 0
  ) {
    return (
      <Container>
        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <LineContainer>
            <LineContainer>
              <Section>
                <ImageCartDark />
                Carregando Meu Pedido...
              </Section>
            </LineContainer>
          </LineContainer>
        </Form>
      </Container>
    );
  }

  return (
    <Container>

      <ShowModal
        isOpen={modalIsOpen}
        //image_url={localProduct.url}
        onRequestClose={closeModal}
        title="Confirmação"
        label="Deseja enviar o pedido para o estabelecimento via WhatsApp ?"
        labelNegativo="Não"
        onNegativo={closeModal}
        labelPositivo="Sim"
        onPositivo={() => {
          closeModal();
          //let text = encodeURIComponent(`👩‍🍳 *Pedido feito com MenuChef* 👩‍🍳`) + '%0A';
          let text = encodeURIComponent(`*Pedido feito com MenuChef*`) + '%0A';

          sale.products.map((product) => {
            text += `%0A%2A${product.amount}x%2A%20%2D%20${encodeURIComponent(product.name || "")}`;
          });

          text += `%0A%0A${encodeURIComponent(`*Nome:* ${sale.deliveryName || ''}`)}`;

          if (sale.deliveryType === "delivery") {
            text += `%0A${encodeURIComponent(`*Valor Entrega:* R\$${(sale.tax || 0).toFixed(2)}`)}`;
          }
          text += `%0A${encodeURIComponent(`*Total:* R\$${(sale.total || 0).toFixed(2)}`)}`;

          if (sale.payments.length === 1) {
            if ((sale.payments[0].changeFor || 0) > 0) {
              text += `%0A%0A${encodeURIComponent('*Levar Cartão:* Não')}`;
              text += `%0A${encodeURIComponent(`*Troco para:* ${(sale.payments[0].changeFor || 0).toFixed(2)}`)}%0A`;
            } 
            else if ((sale.payments[0].name) && (sale.payments[0].name.trim().toUpperCase() === "DINHEIRO")) {
              text += `%0A%0A${encodeURIComponent('*Levar Cartão:* Não')}`;
            }
            else {
              text += `%0A%0A${encodeURIComponent('*Levar Cartão:* Sim')}`;
            }
          } else {
            text += `%0A%0A${encodeURIComponent('*Levar Cartão:* Sim')}`;
          }

          text += `%0A%0A${encodeURIComponent('*Link para acompanhar o pedido:*')}%0A`;
          text += `http://menuchef.com.br${pathname}`;
          var win = window.open(`https://wa.me/55${(sale.store.cellPhone || "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "")}?text=${text}`, '_blank');
          if (win) {
            win.focus();
          }
        }}
      />

      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
        }}
      >
        <Section>
          <ImageFila />
          Aguardando confirmação
        </Section>

        <ContainerCenter>
          <Title>Seu pedido foi entregue ao estabelecimento</Title>
          <SubTitle>Obrigado pela preferência!</SubTitle>
          <ButtonAll onClick={() => navigate(`/order/${saleId}`)}>
            Acompanhe seu pedido
          </ButtonAll>
        </ContainerCenter>
      </Form>
    </Container>
  );
}
