import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.background};
  padding-bottom: 30px;
`;

export const ContainerOpened = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.secondary};
  padding: 5px;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  width: 50vw;
  background-color: ${(props) => props.theme.white};
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 8px;

  @media (max-width: 1024px) {
    width: 95vw;
    padding: 10px;
  }
`;

export const FormTitle = styled.h2`
  color: ${(props) => props.theme.black};
  font-size: 32px;
  font-family: "Work Sans";
  font-weight: 500;
`;

export const Section = styled.p`
  color: ${(props) => props.theme.black};
  font-size: 20px;
  font-family: "Work Sans";
  font-weight: 700;

  padding-bottom: 30px;
  padding-top: 20px;
`;

export const SectionModal = styled.p`
  color: ${(props) => props.theme.primary};
  font-size: 20px;

  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
`;

export const LabelPrice = styled.p`
  color: ${(props) => props.theme.text};
  font-size: 18px;
  font-family: "Work Sans";
  font-weight: 700;
  margin-left: 8px;
`;

export const LabelPriceLeft = styled.p`
  color: ${(props) => props.theme.text};
  font-size: 14px;
  font-family: "Work Sans";
  font-weight: 700;
`;

export const LabelTitleList = styled.p`
  color: ${(props) => props.theme.black};
  font-size: 14px;
  font-weight: 600;
  font-family: "Work Sans";

  margin-bottom: 5px;
`;

export const LabelPriceModal = styled.p`
  color: ${(props) => props.theme.button};
  font-size: 16px;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;

  font-weight: 700;
`;

export const LabelPriceOld = styled.p`
  font-size: 10px;
  text-decoration: line-through;
  font-style: italic;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LabelPriceOldLeft = styled.p`
  font-size: 10px;
  font-family: "Work Sans";
  text-decoration: line-through;
  font-style: italic;
`;

export const LabelPriceOldModal = styled.p`
  font-size: 14px;
  text-decoration: line-through;
  font-style: italic;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
`;

export const Label = styled.label`
  font-size: 12px;
  font-family: "Work Sans";

  line-height: 1.5;
  margin-bottom: 5px;
`;

export const LabelTitle = styled.label`
  font-size: 12px;
  font-family: "Work Sans";
  margin-bottom: 5px;
  color: #000000;
`;

export const Description = styled.label`
  font-size: 14px;
  font-family: "Work Sans";
`;

export const LabelOpend = styled.label`
  font-size: 18px;
  color: ${(props) => props.theme.white};
`;

export const GroupContainer = styled.div`
  display: block;
  width: 100%;
  margin-left: 10px;
`;

export const PromotionContainer = styled.div`
  display: block;
  margin-left: 10px;
  justify-content: center;
  align-items: flex-end;
`;

export const PromotionContainerModal = styled.div`
  display: block;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
`;

export const GroupBox = styled.div<{ isActive: boolean }>`
  background-color: ${(props) =>
    props.isActive ? props.theme.white : props.theme.background};

  border: ${(props) =>
    props.isActive ? `2px solid ${props.theme.background}` : "none"};

  border-radius: 8px;
  height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 20px;

  margin: 10px;

  cursor: pointer;
`;

export const CategoryImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const ButtonContainer = styled.div`
  text-align: right;
  padding-top: 10px;
`;

export const Button = styled.button`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => props.theme.primary}99;
  }
`;

export const ButtonAll = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-top: 10px;

  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.theme.button}99;
  }
`;

export const ButtonOutline = styled.button`
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.button};
  height: 50px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.button};
  border-radius: 5px;
  margin-top: 10px;

  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.theme.background}99;
  }
`;

export const ButtonSecondary = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;

  position: relative;
  padding-left: 20px;
  padding-right: 20px;

  margin: 10px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ButtonCancel = styled.button`
  background-color: ${(props) => props.theme.cancel};
  color: ${(props) => props.theme.white};
  height: 50px;
  border: none;
  border-radius: 5px;

  position: relative;
  padding-left: 20px;
  padding-right: 20px;

  margin: 10px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ContainerTitle = styled.div`
  display: flex;

  border-radius: 10px;
  border: 0.5px solid;
  border-color: ${(props) => props.theme.background};
  padding: 10px;
  margin-bottom: 5px;

  box-shadow: 2px 2px 5px ${(props) => props.theme.background};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerButtonList = styled.div`
  display: flex;

  border-top: 1px solid ${(props) => props.theme.background};
  padding: 10px;

  cursor: pointer;
`;

export const ImageDefault84 = styled.img.attrs(() => ({
  //src: "home-image.svg",
  src: "../../media/default.jpg",
}))`
  width: 84px;
  height: 84px;
  border-radius: 5px;
  margin-left: 10px;
  object-fit: cover;
`;

export const ImageOnChef = styled.img.attrs(() => ({
  //src: "home-image.svg",
  src: "../../logo_padrao.png",
}))`
  width: 90px;
  margin-top: 16px;
`;

export const Image = styled.img.attrs(() => ({
  src: "../../company/italia-no-box.jpg",
}))`
  width: 60%;
`;

export const ImageRemove = styled.img.attrs(() => ({
  src: "../../../media/remove_light.png",
}))`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;

export const ImageCartDark = styled.img.attrs(() => ({
  src: "../../../media/cart_dark.png",
}))`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const ImageMap = styled.img.attrs(() => ({
  src: "../../media/map.png",
}))`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const ImagePhone = styled.img.attrs(() => ({
  src: "../../media/phone.png",
}))`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const ImageCart = styled.img.attrs(() => ({
  src: "../../media/cart_white.png",
}))`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const ImageProduct = styled.img.attrs(() => ({
  src: "../../product/product.jpg",
}))`
  width: 48px;
  height: 48px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

export const RightContainer = styled.div`
  flex: 2;
  display: block;
  justify-content: left;
  align-items: center;
`;

export const ContainerProduct = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const ContainerPrice = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
`;

export const ContainerPriceLeft = styled.div`
  margin-top: 5px;
`;

export const ContainerPriceModal = styled.div`
  display: block;
  justify-content: start;
  align-items: center;
  margin-bottom: 16px;
`;

export const ButtonRounded = styled.div`
  border-width: 1,
  border-color: rgba(0,0,0,0.2),
  align-items: center,
  justify-content: center,
  width: 100px,
  height: 100px,
  background-color: #fff,
  border-radius: 50px,

  cursor: pointer;
`;

export const ContainerPriceAmount = styled.div`
  display: flex;
  width: 100%;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
`;

export const ContainerPhoto = styled.div`
  height: 84px;
  width: 84px;
`;

export const LabelAmount = styled.div`
  display: flex;
  font-size: 22px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  justify-content: center;
`;

export const ButtonAmount = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 5px;

  &:hover {
    filter: opacity(0.9);
  }
`;

// List
export const LabelAmountExtra = styled.div`
  font-size: 16px;
  font-family: "Work Sans";
  font-weight: 600;

  padding-left: 8px;
  padding-right: 8px;
`;

export const ButtonAmountExtra = styled.button`
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.white};
  font-family: "Work Sans";
  font-weight: 600;

  height: 28px;
  width: 28px;
  border: none;
  border-radius: 5px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ListTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 10px;
`;

export const Outline = styled.div<{ isError: boolean }>`
  font-size: 12px;
  font-weight: 600;

  border: 2px solid
    ${(props) => (props.isError ? props.theme.cancel : props.theme.button)};
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${(props) =>
    props.isError ? props.theme.cancel : props.theme.button};

  margin-top: 5px;
  margin-bottom: 8px;
`;

export const RadioOutline = styled.div`
  border: 2px solid ${(props) => props.theme.button};
  border-radius: 5px;
  padding: 10px;
  color: ${(props) => props.theme.button};
  width: 20px;
  height: 20px;
`;

export const RadioChecked = styled.div`
  border: 2px solid ${(props) => props.theme.primary};
  border-radius: 5px;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.button};

  width: 20px;
  height: 20px;

  padding: 10px;
`;

export const ContainerListTitle = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.background};
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ListTitleContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

export const ListTitleMoreContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.button};
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const FormFooter = styled.div`
  width: 40vw;

  @media (max-width: 1024px) {
    width: 90vw;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const RightFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const ContainerOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
  margin-top: 5px;
`;

export const ContainerOptionsBlock = styled.div`
  display: block;
  width: 100%;
  padding: 10px;
  align-items: start;
  justify-content: start;
`;

export const ContainerPaddingDefault = styled.div`
  padding: 10px;
`;

export const LineContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: left;
`;

export const LineContainerRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
