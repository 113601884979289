import React from "react";
import {
  Routes as Router,
  Route,
  useLocation,
} from "react-router-dom";
import StorePage from "../../pages/Store";
import CartPage from "../../pages/Cart";
import PaymentsPage from "../../pages/Payments";

import "react-toastify/dist/ReactToastify.css";

export default function Companies() {
  //let match = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <Router>
      <Route path={`${pathname}/:suffix/group/:groupId/products`}>
        <h3>Listagem de Produtos</h3>
      </Route>
      <Route path={`${pathname}/:suffix/cart`}>
        <CartPage />
      </Route>
      <Route path={`${pathname}/:suffix/payments`}>
        <PaymentsPage />
      </Route>
      <Route path={`${pathname}/:suffix`}>
        <StorePage />
      </Route>
      <Route path={`${pathname}`}>
        <h3>Listagem de empresas</h3>
      </Route>
    </Router>
  );
}
