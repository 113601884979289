import {
  Container,
  Form,
  FormTitle,
  Section,
  ImageOnChef,
  Label,
  ContainerProduct,
  ContainerButtonList,
  LabelPrice,
  GroupContainer,
  LabelTitleList,
  ContainerOptions,
  ContainerOptionsBlock,
  PriceLabel,
  ButtonAll,
  ImageDelivery,
  ImageFormaPagto,
  ImageMesa,
  ImageCliente,
  ImageRemove,
  ButtonOutline,
  LeftContainer,
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ShowModal from "../../components/ShowModal";
import Input from "../../components/Input";
import {
  ICart,
  IAddress,
  IPayment,
  IStore,
  ISale,
  ISaleProduct,
  ICity,
  ISaleProductExtra,
  ISaleProductFlavour,
  ISaleProductFlavourExtra,
  IUser,
  ISaleForm,
} from "../../classes/interfaces";
import api from "../../classes/api";
import { toast } from "react-toastify";
import { DualRing } from "react-spinners-css";
import InputCurrency from "../../components/InputCurrency";
import Select from "react-select";
import { Checkbox } from "@mui/material";

export default function Payments() {
  //const history = useHistory();
  //let match = useRouteMatch();
  const navigate = useNavigate();
  const { suffix } = useParams<{ suffix: string }>();
  const [state, setState] = React.useState({ status: "create" });
  const [payments, setPayments] = React.useState<IPayment[]>();
  const [cities, setCities] = React.useState<ICity[]>();
  const [cityOptions, setCityOptions] = React.useState<any[]>([]);
  const [districts, setDistricts] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const store: IStore = JSON.parse(
    sessionStorage.getItem("@menuchef/store") || "{}"
  );

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const clearAddress = () => {
    formValues.address = "";
    formValues.cityId = store.city.id;
    formValues.city = store.city.name;
    formValues.complement = "";
    formValues.district = "";
    formValues.districtId = null;
    formValues.number = "";
    formValues.reference = "";
    setFormValues(formValues);
    loadDistricts(formValues.cityId);
  };

  const [isAddress, setIsAddress] = React.useState(false);
  function openModalAddress() {
    clearAddress();
    api()
      //.get(`/cities?state=${store.city.state}`)
      .get(`/cities/store/${suffix}`)
      .then((res) => {
        //console.log(res);
        if (res.data === undefined || res.data.code === undefined) {
          setState({ status: "404" });
          return;
        }
        if (res.data.code !== 200) {
          setState({ status: res.data.code });
          return;
        }
        setCities(res.data.data);
        setIsAddress(true);
      })
      .catch((error) => {
        setState({ status: "404" });
      });
  }
  function closeModalAddress() {
    setIsAddress(false);
  }

  const [isMoney, setIsMoney] = React.useState(false);
  function openModalMoney() {
    setIsMoney(true);
  }
  function closeModalMoney() {
    setIsMoney(false);
  }

  const [isDeleteAddress, setIsDeleteAddress] = React.useState(false);
  const [keyAddress, setKeyAddress] = React.useState("");
  function deleteAddress(key: string) {
    setKeyAddress(key);
    setIsDeleteAddress(true);
  }
  function closeDeleteAddress() {
    setIsDeleteAddress(false);
  }

  function to(id: string) {
    const element = document.getElementById(id);
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
      });
  }

  function loadDistricts(cityId: number) {
    setLoading(true);
    setDistricts([]);
    api()
      .get(`/district/city/${cityId}/${store.id}`)
      .then((res) => {
        //console.log(res);
        if (res.data === undefined || res.data.code === undefined) {
          setState({ status: "404" });
          return;
        }
        if (res.data.code !== 200) {
          setState({ status: res.data.code });
          return;
        }
        const aux: any[] = [];
        const items: any[] = res.data.data;
        items.map((item) => {
          aux.push({
            value: item.id,
            label: item.name,
            tax: item.tax,
          });
        });
        setDistricts(aux);
        setLoading(false);
      })
      .catch((error) => {
        setState({ status: "404" });
        setLoading(false);
      });
  }

  /*const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontFamily: "Work Sans",
      fontWeight: theme.typography.fontWeightRegular,
    },
    details: {
      display: "block",
    },
    checked: {
      color: teal[400],
      "&$checked": {
        color: teal[600],
      },
      margin: 0,
      marginLeft: 10,
      padding: 0,
    },
  }));

  const classes = useStyles();*/

  let cart: ICart = {
    grouping_last: 0,
    product_last: 0,
    products: [],
    payments: [],
    total: 0.0,
    styleSale: "menu",
  };
  const sessionCart = sessionStorage.getItem("@menuchef/cart");
  if (sessionCart !== null) {
    cart = JSON.parse(sessionCart);
  }

  let addresses: IAddress[] = [];
  const sessionAddresses = localStorage.getItem("@menuchef/addresses");
  if (sessionAddresses !== null) {
    addresses = JSON.parse(sessionAddresses);
  }

  let user: IUser = {
    name: "",
    phone: "",
  };
  const localUser = localStorage.getItem("@menuchef/user");
  if (localUser !== null) {
    user = JSON.parse(localUser);
  }

  const [formValues, setFormValues] = useState<ISaleForm>({
    taxPayerIdentificationNumber: "",
    name: user.name,
    phone: user.phone,
    zipCode: store.zipCode,
    address: "",
    number: "",
    district: "",
    cityId: store.city.id,
    city: store.city.name,
    complement: "",
    reference: "",
    deliveryType: "delivery",
    changeFor: "",
    deliveryFee: 0.0,
  });

  const [localAddress, setLocalAddress] = useState<IAddress>();

  const getPayments = () => {
    if (!payments || payments.length <= 0) {
      api()
        .get(`/payment?suffix=${suffix}`)
        .then((res) => {
          //console.log(res);
          if (res.data === undefined || res.data.code === undefined) {
            setState({ status: "404" });
          }
          if (res.data.code !== 200) {
            setState({ status: res.data.code });
          }
          setPayments(res.data.data);
        })
        .catch((error) => {
          console.log(error);
          setState({ status: "404" });
        });
    }
  };

  useEffect(() => {
    getPayments();
    loadDistricts(store.city.id);
  }, []);

  useEffect(() => {
    const options: any[] = [];
    if (cities && cities !== null && cities.length > 0) {
      cities.map((city) => {
        options.push({
          value: city.id,
          label: city.name,
        });
      });
    }
    setCityOptions(options);
  }, [cities]);

  if (!payments || payments.length <= 0) {
    return (
      <Container>
        <ImageOnChef />
        <Form>
          <FormTitle>Carregando...</FormTitle>
        </Form>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <ShowModal
          isOpen={loading}
          //setOpen={setIsOpen}
          //onRequestClose={closeModal}
          title={"Carregando..."}
          label=""
          centered={true}
          size="sm"
          document={
            <LeftContainer>
              <DualRing color="#00796B" />
            </LeftContainer>
          }
          isFooter={false}
        />

        <ShowModal
          isOpen={modalIsOpen}
          //image_url={localProduct.url}
          onRequestClose={closeModal}
          title="Meus Endereços"
          label="Clique sobre o endereço para indicar onde será entregue o seu pedido"
          document={
            <div style={{ marginTop: "20px" }}>
              {addresses.length <= 0 ? (
                <LabelTitleList>
                  Você ainda não tem nenhum endereço por aqui, clique no botão
                  "Novo Endereço" para adicionar um endereço.
                </LabelTitleList>
              ) : (
                ""
              )}
              {addresses.map((address) => (
                <ContainerButtonList
                  key={address.key}
                  onClick={() => {
                    setLocalAddress(address);
                    formValues.deliveryFee = store.tax;
                    formValues.address = address.address;
                    formValues.number = address.number;
                    formValues.district = address.district;
                    formValues.districtId = address.districtId;
                    formValues.complement = address.complement;
                    formValues.reference = address.reference;
                    formValues.zipCode = address.zipCode;
                    formValues.cityId = address.cityId;
                    formValues.city = address.city;

                    const location = `${formValues.address}, ${formValues.number} - ${formValues.district}, ${formValues.city} - ${store.city.state}, ${formValues.zipCode}, Brasil`;
                    setLoading(true);
                    api()
                      .get(
                        `/distance/calculate/${store.id}/${location}/${formValues.districtId}`
                      )
                      .then((res) => {
                        setLoading(false);
                        //console.log(res);
                        if (
                          res.data === undefined ||
                          res.data.code === undefined
                        ) {
                          toast(res.data, {
                            type: "error",
                            autoClose: 3000,
                          });
                          return;
                        }
                        if (res.data.code !== 200) {
                          toast(res.data, {
                            type: "error",
                            autoClose: 3000,
                          });
                          return;
                        }
                        formValues.deliveryFee = res.data.data.delivery_fee;
                        setFormValues(formValues);
                        closeModal();
                      })
                      .catch((error) => {
                        setLoading(false);
                        //setState({ status: "404", token: state.token });
                        toast(error, {
                          type: "error",
                          autoClose: 3000,
                        });
                        return;
                      });
                  }}
                >
                  <ContainerProduct>
                    <GroupContainer>
                      <LabelTitleList>
                        {address.address + ", " + address.number}
                      </LabelTitleList>
                      <Label>
                        {address.district +
                          " - " +
                          address.city +
                          (address.complement !== ""
                            ? " - Complemento: " + address.complement
                            : "") +
                          (address.reference !== ""
                            ? " - Referência: " + address.reference
                            : "")}
                      </Label>
                    </GroupContainer>
                  </ContainerProduct>
                  <ImageRemove onClick={() => deleteAddress(address.key)} />
                </ContainerButtonList>
              ))}
            </div>
          }
          onNegativo={closeModal}
          labelPositivo="Novo Endereço"
          onPositivo={() => {
            closeModal();
            openModalAddress();
          }}
        />

        <ShowModal
          isOpen={isDeleteAddress}
          onRequestClose={closeDeleteAddress}
          title="Deletar Endereço"
          label="Deseja realmente remover o endereço ?"
          centered={false}
          onNegativo={closeDeleteAddress}
          labelPositivo="Deletar"
          onPositivo={() => {
            addresses.map((address) => {
              if (address.key == keyAddress) {
                const index = addresses.indexOf(address);
                if (index !== -1) {
                  addresses.splice(index, 1);
                }
              }
            });
            localStorage.setItem(
              "@menuchef/addresses",
              JSON.stringify(addresses)
            );
            closeDeleteAddress();
            clearAddress();
            setState({ status: "remove address" });
          }}
        />

        <ShowModal
          isOpen={isAddress}
          onRequestClose={() => {
            closeModalAddress();
            clearAddress();
          }}
          title="Novo Endereço"
          label="Cadastre um novo endereço para entrega"
          document={
            <div>
              <ContainerOptionsBlock>
                <Input
                  label="CEP"
                  name="zipCode"
                  value={formValues.zipCode}
                  onChange={setFormValues}
                  maxLenght={8}
                  numberOnly={true}
                />

                <div
                  style={{
                    marginBottom: "1em",
                  }}
                >
                  {cities ? (
                    <>
                      <div>
                        <Label>Cidade:</Label>
                      </div>
                      {/*<select
                      value={formValues.cityId}
                      onChange={(ev) => {
                        //formValues.cityId = Number.parseInt(ev.target.value);
                        setFormValues((previousState: any) => ({
                          ...previousState,
                          ["cityId"]: Number.parseInt(ev.target.value),
                        }));
                      }}
                    >
                      {cities.map((city) => (
                        <option value={city.id.toString()}>{city.name}</option>
                      ))}
                      </select>*/}
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={{
                          value: formValues.cityId.toString(),
                          label: formValues.city,
                        }}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        //name="color"
                        options={cityOptions}
                        onChange={(option) => {
                          if (option !== null) {
                            const cityId = Number.parseInt(option.value);
                            setFormValues((previousState: any) => ({
                              ...previousState,
                              ["cityId"]: cityId,
                              ["city"]: option.label,
                              ["districtId"]: null,
                              ["district"]: null,
                            }));
                            loadDistricts(cityId);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <Input
                      label="Cidade"
                      name="city"
                      value={formValues.city}
                      onChange={setFormValues}
                    />
                  )}
                </div>

                <Input
                  label="Endereço"
                  name="address"
                  value={formValues.address}
                  onChange={setFormValues}
                  maxLenght={100}
                />

                <Input
                  label="Número"
                  name="number"
                  value={formValues.number}
                  onChange={setFormValues}
                  maxLenght={10}
                />

                {/*<Input
                  label="Bairro"
                  name="district"
                  value={formValues.district}
                  onChange={setFormValues}
                  maxLenght={80}
                  />*/}
                <div
                  style={{
                    marginBottom: "1em",
                  }}
                >
                  {districts.length > 0 ? (
                    <>
                      <div>
                        <Label>Bairro:</Label>
                      </div>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        //defaultValue={colourOptions[0]}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        //name="color"
                        options={districts}
                        onChange={(option) => {
                          setFormValues((previousState: any) => ({
                            ...previousState,
                            ["districtId"]: Number.parseInt(option.value),
                            ["district"]: option.label,
                            ["deliveryFee"]: option.tax,
                          }));
                        }}
                      />
                    </>
                  ) : (
                    <Input
                      label="Bairro"
                      name="district"
                      value={formValues.district}
                      onChange={setFormValues}
                      maxLenght={80}
                    />
                  )}
                </div>

                <Input
                  label="Complemento"
                  name="complement"
                  value={formValues.complement}
                  onChange={setFormValues}
                  maxLenght={100}
                />

                <Input
                  label="Referência"
                  name="reference"
                  value={formValues.reference}
                  onChange={setFormValues}
                  maxLenght={100}
                />
              </ContainerOptionsBlock>
            </div>
          }
          onNegativo={() => {
            closeModalAddress();
            clearAddress();
          }}
          onPositivo={() => {
            if (formValues.zipCode.trim() === "") {
              toast("É necessário informar o CEP!", {
                type: "error",
                autoClose: 2000,
              });
              return;
            }
            if (!formValues.cityId || formValues.cityId === null) {
              toast("É necessário escolher uma cidade!", {
                type: "error",
                autoClose: 2000,
              });
              return;
            }
            if (districts && districts.length > 0) {
              if (!formValues.districtId || formValues.districtId === null) {
                toast("É necessário escolher um bairro!", {
                  type: "error",
                  autoClose: 2000,
                });
                return;
              }
            } else {
              if (formValues.district.trim() === "") {
                toast("É necessário informar o Endereço!", {
                  type: "error",
                  autoClose: 2000,
                });
                return;
              }
            }
            if (formValues.address.trim() === "") {
              toast("É necessário informar o Endereço!", {
                type: "error",
                autoClose: 2000,
              });
              return;
            }
            if (formValues.number.trim() === "") {
              toast("É necessário informar o Número do Endereço!", {
                type: "error",
                autoClose: 2000,
              });
              return;
            }
            const newAddress: IAddress = {
              key: (addresses.length + 1).toString(),
              address: formValues.address,
              number: formValues.number,
              district: formValues.district,
              districtId: formValues.districtId,
              cityId: formValues.cityId,
              city: formValues.city,
              zipCode: formValues.zipCode,
              complement: formValues.complement,
              reference: formValues.reference,
            };
            addresses.push(newAddress);
            localStorage.setItem(
              "@menuchef/addresses",
              JSON.stringify(addresses)
            );
            closeModalAddress();
            openModal();
            setState({ status: "new address" });
            clearAddress();
          }}
        />

        <ShowModal
          isOpen={isMoney}
          onRequestClose={closeModalAddress}
          title="Forma de Pagamento - Dinheiro"
          label="Coloque o valor do dinheiro a ser utilizado"
          document={
            <div>
              <ContainerOptionsBlock>
                <InputCurrency
                  label="Troco para"
                  name="changeFor"
                  value={formValues.changeFor}
                  onChange={setFormValues}
                />
              </ContainerOptionsBlock>
            </div>
          }
          onNegativo={closeModalMoney}
          labelNeutro="Sem troco"
          onNeutro={() => {
            if (!cart.payments) {
              cart.payments = [];
            }
            let obj = null;
            cart.payments.map((pay) => {
              if (pay.money) {
                obj = pay;
              }
            });
            if (obj !== null) {
              const index = cart.payments.indexOf(obj);
              if (index > -1) {
                cart.payments.splice(index, 1);
              }
            } else {
              payments.map((pay) => {
                if (pay.money) {
                  if (formValues.changeFor !== "") {
                    pay.changeFor = 0;
                  }
                  cart.payments.push(pay);
                }
              });
            }
            formValues.changeFor = "";
            sessionStorage.setItem("@menuchef/cart", JSON.stringify(cart));
            setState({ status: "payment money" });
            closeModalMoney();
          }}
          onPositivo={() => {
            if (!cart.payments) {
              cart.payments = [];
            }
            let obj = null;
            cart.payments.map((pay) => {
              if (pay.money) {
                obj = pay;
              }
            });
            if (obj !== null) {
              const index = cart.payments.indexOf(obj);
              if (index > -1) {
                cart.payments.splice(index, 1);
                formValues.changeFor = "";
              }
            } else {
              let containsError = false;
              payments.map((pay) => {
                if (pay.money && !containsError) {
                  if (formValues.changeFor !== "") {
                    pay.changeFor = Number.parseFloat(formValues.changeFor);
                    if (pay.changeFor < cart.total + formValues.deliveryFee) {
                      toast(
                        "O valor do troco não pode ser menor que o total a pagar!",
                        { type: "error", autoClose: 2000 }
                      );
                      pay.changeFor = 0;
                      pay.value = 0;
                      formValues.changeFor = "";
                      containsError = true;
                    }
                  }
                  if (!containsError) {
                    cart.payments.push(pay);
                  }
                }
              });
            }

            sessionStorage.setItem("@menuchef/cart", JSON.stringify(cart));
            setState({ status: "payment money" });
            closeModalMoney();
          }}
        />

        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <Section>
            <ImageCliente />
            Identificação
          </Section>
          <Input
            label="Nome"
            name="name"
            value={formValues.name}
            onChange={setFormValues}
            maxLenght={100}
          />

          <Input
            label="Telefone"
            name="phone"
            value={formValues.phone}
            onChange={setFormValues}
            maxLenght={20}
            numberOnly={true}
          />

          <Input
            label="CPF ou CNPJ para documento fiscal (opcional)"
            name="taxPayerIdentificationNumber"
            value={formValues.taxPayerIdentificationNumber}
            onChange={setFormValues}
            maxLenght={14}
            numberOnly={true}
          />
        </Form>

        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <Section>
            <ImageMesa />
            Tipo da Entrega
          </Section>

          <ContainerButtonList
            key="btnDelivery"
            onClick={() => {
              setFormValues((previousState: any) => ({
                ...previousState,
                ["deliveryType"]: "delivery",
              }));
            }}
          >
            <ContainerProduct>
              <GroupContainer>
                <LabelTitleList>Delivery</LabelTitleList>
                <Label>O estabelecimento entrega o seu pedido</Label>
              </GroupContainer>
            </ContainerProduct>
            <Checkbox
              //className={classes.checked}
              checked={formValues.deliveryType === "delivery"}
              color="primary"
              name="deliveryType"
              onChange={(ev) => {
                setFormValues((previousState: any) => ({
                  ...previousState,
                  ["deliveryType"]: "delivery",
                }));
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </ContainerButtonList>

          <ContainerButtonList
            key="btnPickup"
            onClick={() => {
              setFormValues((previousState: any) => ({
                ...previousState,
                ["deliveryType"]: "pickUp",
              }));
            }}
          >
            <ContainerProduct>
              <GroupContainer>
                <LabelTitleList>Retirar no Local</LabelTitleList>
                <Label>
                  Quero retirar meu pedido no local do estabelecimento
                </Label>
              </GroupContainer>
            </ContainerProduct>
            <Checkbox
              //className={classes.checked}
              checked={formValues.deliveryType === "pickUp"}
              color="primary"
              name="deliveryType"
              onChange={(ev) => {
                setFormValues((previousState: any) => ({
                  ...previousState,
                  ["deliveryType"]: "pickUp",
                }));
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </ContainerButtonList>
        </Form>

        {formValues.deliveryType === "delivery" ? (
          <>
            <Form
              onSubmit={(ev) => {
                ev.preventDefault();
              }}
            >
              <Section>
                <ImageDelivery />
                Local da Entrega
              </Section>

              <ContainerButtonList key="btnAddress" onClick={() => openModal()}>
                <ContainerProduct>
                  <GroupContainer>
                    <LabelTitleList>Endereço</LabelTitleList>
                    <Label>
                      {localAddress !== undefined
                        ? localAddress.address +
                          ", " +
                          localAddress.number +
                          " - " +
                          localAddress.district
                        : "Escolha o endereço de entrega"}
                    </Label>
                  </GroupContainer>
                </ContainerProduct>
                <Checkbox
                  //className={classes.checked}
                  checked={localAddress !== undefined}
                  color="primary"
                  //name="deliveryType"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </ContainerButtonList>
            </Form>
          </>
        ) : (
          <></>
        )}

        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <Section>
            <ImageFormaPagto />
            Forma de Pagamento
          </Section>

          <div>
            <ContainerOptions>
              <ContainerOptionsBlock>
                <PriceLabel>Total do pedido</PriceLabel>
                <LabelPrice>{cart.total.toFixed(2)}</LabelPrice>
              </ContainerOptionsBlock>
              <ContainerOptionsBlock>
                <PriceLabel>Taxa de Entrega</PriceLabel>
                <LabelPrice>
                  {formValues.address === "" && store.distances.length > 0
                    ? "Variável"
                    : formValues.deliveryType === "delivery"
                    ? formValues.deliveryFee.toFixed(2)
                    : "~"}
                </LabelPrice>
              </ContainerOptionsBlock>
              <ContainerOptionsBlock>
                <PriceLabel>Total a pagar</PriceLabel>
                <LabelPrice>
                  {(
                    cart.total +
                    (formValues.deliveryType === "delivery"
                      ? formValues.deliveryFee
                      : 0)
                  ).toFixed(2)}
                </LabelPrice>
              </ContainerOptionsBlock>
            </ContainerOptions>
            {payments?.map((payment) =>
              payment.money ? (
                <ContainerButtonList
                  key={payment.id}
                  onClick={() => {
                    let obj = null;
                    cart.payments.map((pay) => {
                      if (pay.money) {
                        obj = pay;
                      }
                    });
                    if (obj !== null) {
                      const index = cart.payments.indexOf(obj);
                      if (index > -1) {
                        cart.payments.splice(0, cart.payments.length);
                        formValues.changeFor = "";
                      }
                    } else {
                      cart.payments.splice(0, cart.payments.length);
                      formValues.changeFor = "";
                      openModalMoney();
                    }
                    sessionStorage.setItem(
                      "@menuchef/cart",
                      JSON.stringify(cart)
                    );
                    setState({ status: "payment " + payment.name });
                  }}
                >
                  <ContainerProduct>
                    <GroupContainer>
                      <LabelTitleList>{payment.name}</LabelTitleList>
                      {formValues.changeFor !== "" ? (
                        <div>
                          <Label>
                            {"Troco para " +
                              Number.parseFloat(formValues.changeFor).toFixed(
                                2
                              )}
                          </Label>
                        </div>
                      ) : (
                        ""
                      )}
                      {formValues.changeFor !== "" ? (
                        <div>
                          <Label>
                            {"Valor do troco " +
                              (
                                Number.parseFloat(formValues.changeFor) -
                                cart.total -
                                (formValues.deliveryType === "delivery"
                                  ? formValues.deliveryFee
                                  : 0)
                              ).toFixed(2)}
                          </Label>
                        </div>
                      ) : (
                        ""
                      )}
                    </GroupContainer>
                  </ContainerProduct>
                  <Checkbox
                    //className={classes.checked}
                    checked={cart.payments.some((pay) => pay.id === payment.id)}
                    color="primary"
                    //name="deliveryType"
                    onClick={() => {
                      cart.payments.splice(0, cart.payments.length);
                      formValues.changeFor = "";
                      openModalMoney();
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </ContainerButtonList>
              ) : (
                <ContainerButtonList
                  key={payment.id}
                  onClick={() => {
                    if (!cart.payments) {
                      cart.payments = [];
                    }
                    let obj = null;
                    cart.payments.map((pay) => {
                      if (pay.id === payment.id) {
                        obj = pay;
                      }
                    });
                    if (obj !== null) {
                      const index = cart.payments.indexOf(obj);
                      if (index > -1) {
                        cart.payments.splice(0, cart.payments.length);
                        formValues.changeFor = "";
                      }
                    } else {
                      cart.payments.splice(0, cart.payments.length);
                      formValues.changeFor = "";

                      cart.payments.push(payment);
                    }
                    sessionStorage.setItem(
                      "@menuchef/cart",
                      JSON.stringify(cart)
                    );
                    setState({ status: "payment " + payment.name });
                  }}
                >
                  <ContainerProduct>
                    <GroupContainer>
                      <LabelTitleList>{payment.name}</LabelTitleList>
                    </GroupContainer>
                  </ContainerProduct>
                  <Checkbox
                    //className={classes.checked}
                    checked={cart.payments.some((pay) => pay.id === payment.id)}
                    onChange={(value) => {
                      setState({ status: "onChange " + payment.name });
                    }}
                    color="primary"
                    //name="deliveryType"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </ContainerButtonList>
              )
            )}
            <ButtonOutline
              onClick={() => {
                navigate(`/store/${suffix}`);
                /*history.push(
                  match.path
                    .replaceAll("/cart", "")
                    .replaceAll(":suffix", suffix)
                    .replaceAll("/payments", "")
                );*/
              }}
            >
              Adicionar mais itens
            </ButtonOutline>
            {cart.styleSale === "share" ? (
              <ButtonAll>Compartilhar</ButtonAll>
            ) : (
              <></>
            )}
            {cart.styleSale === "sale" ? (
              state.status !== "enviando" ? (
                <ButtonAll
                  onClick={() => {
                    user.name = formValues.name;
                    user.phone = formValues.phone;

                    if (user.name === "") {
                      toast("É necessário informar o campo Nome.", {
                        type: "error",
                        autoClose: 2000,
                      });
                      return;
                    }
                    if (user.phone === "") {
                      toast("É necessário informar o campo Telefone.", {
                        type: "error",
                        autoClose: 2000,
                      });
                      return;
                    }

                    localStorage.setItem(
                      "@menuchef/user",
                      JSON.stringify(user)
                    );

                    const sale: ISale = {
                      store: {
                        id: store.id,
                      },
                      tax:
                        formValues.deliveryType === "delivery"
                          ? formValues.deliveryFee
                          : 0,
                      taxPayerIdentificationNumber: (
                        formValues.taxPayerIdentificationNumber || ""
                      ).trim(),
                      deliveryName: (formValues.name || "").trim(),
                      deliveryType: formValues.deliveryType,
                      deliveryAddress: (formValues.address || "").trim(),
                      deliveryNumber: (formValues.number || "").trim(),
                      deliveryDistrict: (formValues.district || "").trim(),
                      deliveryComplement: (formValues.complement || "").trim(),
                      deliveryReference: (formValues.reference || "").trim(),
                      deliveryCity: {
                        id: formValues.cityId,
                      },
                      deliveryZipCode: (formValues.zipCode || "").trim(),
                      phone: (formValues.phone || "").trim(),
                      note: "",
                      products: [],
                      payments: [],
                    };

                    cart.products.map((product) => {
                      const newProduct: ISaleProduct = {
                        amount: product.amount,
                        price: product.price,
                        discount: 0,
                        addition: 0,
                        extras: [],
                        flavours: [],
                        note: (product.obs || "").trim(),
                      };

                      if (product.sizeId && product.sizeId !== null) {
                        newProduct.size = {
                          id: product.sizeId,
                        };
                      } else {
                        newProduct.product = {
                          id: product.product_id,
                        };
                      }

                      product.extras.map((extra) => {
                        const newExtra: ISaleProductExtra = {
                          extra: {
                            id: extra.id,
                          },
                          amount: extra.amount,
                          price: extra.price,
                        };

                        if (extra.stepId !== undefined) {
                          newExtra.step = { id: extra.stepId };
                        }

                        newProduct.extras.push(newExtra);
                      });

                      if (product.flavours) {
                        product.flavours.map((flavour) => {
                          const newFlavour: ISaleProductFlavour = {
                            product: {
                              id: flavour.product_id,
                            },
                            amount: flavour.amount,
                            price: flavour.price,
                            addition: 0.0,
                            discount: 0.0,
                            note: flavour.obs,
                            extras: [],
                          };

                          flavour.extras.map((extra) => {
                            const newExtra: ISaleProductFlavourExtra = {
                              extra: {
                                id: extra.id,
                              },
                              amount: extra.amount,
                              price: extra.price,
                            };

                            newFlavour.extras.push(newExtra);
                          });

                          newProduct.flavours.push(newFlavour);
                        });
                      }

                      sale.products.push(newProduct);
                    });

                    let valuePaymentMoney = 0;
                    cart.payments.map((payment) => {
                      if (payment.money) {
                        valuePaymentMoney = payment.value;
                      }
                    });

                    if (cart.payments.length > 1) {
                      let payable = cart.total + sale.tax;
                      if (valuePaymentMoney > 0) {
                        payable -= valuePaymentMoney;
                      }
                      if (payable <= 0) {
                        toast(
                          "Suas formas de pagamentos excedem o valor total do pedido. Por favor, selecione apenas as que forem ser usadas.",
                          {
                            type: "error",
                            autoClose: 2000,
                          }
                        );
                        return;
                      }
                      cart.payments.map((payment) => {
                        if (!payment.money) {
                          payment.value = payable / (cart.payments.length - 1);
                        }
                      });
                    }

                    cart.payments.map((payment) => {
                      sale.payments.push({
                        payment: {
                          id: payment.id,
                        },
                        value: payment.value || cart.total + sale.tax,
                        changeFor: payment.changeFor,
                      });
                    });

                    // validate
                    if (sale.deliveryName === "") {
                      toast("É necessário preencher o nome.", {
                        type: "error",
                        autoClose: 2000,
                      });
                      return;
                    }

                    if (sale.phone === "") {
                      toast("É necessário preencher o telefone.", {
                        type: "error",
                        autoClose: 2000,
                      });
                      return;
                    }

                    if (sale.deliveryType === "delivery") {
                      if (
                        sale.deliveryAddress === "" &&
                        sale.deliveryDistrict === ""
                      ) {
                        toast("É necessário escolher um endereço.", {
                          type: "error",
                          autoClose: 2000,
                        });
                        return;
                      }

                      if (!formValues.cityId || formValues.cityId === null) {
                        toast("O endereço selecionado precisa uma cidade!", {
                          type: "error",
                          autoClose: 2000,
                        });
                        return;
                      }
                    }

                    if (sale.products.length <= 0) {
                      toast("Nenhum produto encontrado no carrinho.", {
                        type: "error",
                        autoClose: 2000,
                      });
                      return;
                    }

                    if (sale.payments.length <= 0) {
                      toast(
                        "É necessário selecionar no mínimo uma forma de pagamento.",
                        {
                          type: "error",
                          autoClose: 2000,
                        }
                      );
                      return;
                    }

                    setState({ status: "enviando" });
                    api()
                      .post("/sale", sale)
                      .then((res) => {
                        //console.log(res);
                        if (
                          res.data === undefined ||
                          res.data.code === undefined
                        ) {
                          toast(
                            "Erro inesperado. Tente novamente mais tarde!",
                            {
                              type: "error",
                              autoClose: 2000,
                            }
                          );
                          return;
                        }
                        if (res.data.code !== 200) {
                          if (res.data.status === "store closed") {
                            toast("Desculpe, a loja está fechada no momento.", {
                              type: "error",
                              autoClose: 2000,
                            });
                          }
                          return;
                        }
                        const saleId = res.data.data.sale.id;
                        /*cart.grouping_last = 0;
                      cart.product_last = 0;
                      cart.styleSale = "";
                      cart.products = [];
                      cart.payments = [];*/
                        sessionStorage.removeItem("@menuchef/cart");
                        toast(
                          "Seu pedido está com o estabelecimento, em instantes será produzido.",
                          {
                            type: "success",
                            autoClose: 2000,
                            onClose: () => {
                              /*history.push(
                            match.path
                              .replaceAll("/cart", "")
                              .replaceAll(":suffix", suffix)
                              .replaceAll("/payments", "")
                          );*/
                              //history.push(`/terminate/${saleId}`);
                              navigate(`/terminate/${saleId}`);
                            },
                          }
                        );
                      })
                      .catch((error) => {
                        //console.log(error.response);
                        toast("Erro no servidor. Tente novamente mais tarde!", {
                          type: "error",
                          autoClose: 2000,
                        });
                      });
                  }}
                >
                  Finalizar Compra
                </ButtonAll>
              ) : (
                <LeftContainer>
                  <DualRing color="#00796B" />
                </LeftContainer>
              )
            ) : (
              <></>
            )}
          </div>
        </Form>
      </Container>
    </>
  );
}
