import React from "react";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes as Router, Route } from "react-router-dom";
import HomePage from "./pages/Home";
import NewPage from "./pages/New";
import CompaniesPage from "./pages/Companies";
import StorePage from "./pages/Store";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SaleTerminatePage from "./pages/SaleTerminate";
import OrderPage from "./pages/Order";
import CartPage from "./pages/Cart";
import Payments from "./pages/Payments";
import theme from "./themes";

function App() {
  const themeStyled = {
    primary: "#322153",
    secondary: "#28ca91",
    background: "#F0F0F5",
    text: "#263238",
    label: "#9E9E9E",
    white: "#fff",
    button: "#00796B",
    cancel: "#D32F2F",
    shadow: "#E0E0E0",
    accent: "#1976D2",
  };

  return (
    <ThemeProvider theme={themeStyled}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Router>
            <Route index path="/" element={<HomePage />} />
            <Route path="/new" element={<NewPage />} />
            <Route path="/store" element={<CompaniesPage />} />
            <Route
              path="/store/:companyId/group/:groupId/products"
              element={<NewPage />}
            />
            <Route
              path="/store/:suffix/table/:tableId/cart"
              element={<CartPage />}
            />
            <Route
              path="/store/:suffix/table/:tableId"
              element={<StorePage />}
            />
            <Route path="/store/:suffix/cart" element={<CartPage />} />
            <Route path="/store/:suffix/payments" element={<Payments />} />
            <Route path="/store/:suffix" element={<StorePage />} />
            <Route path="/terminate/:saleId" element={<SaleTerminatePage />} />
            <Route path="/order/:saleId" element={<OrderPage />} />

            <Route path="/menu/:suffix" element={<StorePage styleSale="menu" />} />
          </Router>
        </BrowserRouter>
        <ToastContainer />
      </MuiThemeProvider>
    </ThemeProvider>
  );
}

export default App;
