import React, { useState } from "react";
import { ICartProduct, IStore } from "../../classes/interfaces";
import { round2, round2string } from "../../classes/numbers";
import CollapsedProductExtra from "../CollapsedProductExtra";
import ShowModal from "../ShowModal";
import {
  PriceContainer,
  ButtonAdd,
  SectionModal,
  ContainerPrice,
} from "./styles";

interface InputProps {
  open: boolean;
  product: ICartProduct | null;
  onClose(event: React.MouseEvent | React.KeyboardEvent): void;
  onExecute(event: React.MouseEvent | React.KeyboardEvent): void;
}

export default function ModalFracionados(props: InputProps) {
  const [state, setState] = useState({ status: "" });
  const store: IStore = JSON.parse(
    sessionStorage.getItem("@menuchef/store") || "{}"
  );

  function calcTotalLocalProduct() {
    let totalExtra = 0;
    if (props.product !== null) {
      props.product.total = 0;
      if (store !== undefined && store !== null) {
        props.product.flavours!.map((flavour: ICartProduct) => {
          if ((store?.fractionalPrice || "average") === "average") {
            props.product!.total += round2(
              (flavour.amount || 0) * flavour.price
            );
          } else {
            if (props.product!.total < flavour.price) {
              props.product!.total = flavour.price;
            }
          }
        });
      }

      if (props.product.extras) {
        props.product.extras.map((extra) => {
          if (extra.amount && extra.amount > 0) {
            totalExtra += round2(extra.amount * extra.price);
          }
        });
      }

      if (props.product.flavours) {
        if (props.product.flavours) {
          props.product.flavours.map((flavour) => {
            flavour.extras.map((extra) => {
              if (extra.amount && extra.amount > 0) {
                totalExtra += round2(
                  (extra.amount * extra.price) / props.product!.flavours!.length
                );
              }
            });
          });
        }
      }

      props.product.totalExtra = totalExtra;
      props.product.total += props.product.totalExtra;

      // arredondar os valores para ficarem corretos no pagamento
      props.product.total = round2(props.product.total);
    }
    setState({
      status: "calc product",
    });
  }

  React.useEffect(() => {
    calcTotalLocalProduct();
  }, [props.product]);

  return (
    <ShowModal
      isOpen={props.open}
      onRequestClose={props.onClose}
      title={props.product?.product || "Sem produto"}
      label=""
      document={
        <div>
          <PriceContainer>
            <SectionModal>Deseja escolher adicional por sabor ?</SectionModal>
          </PriceContainer>
          {props.product !== null &&
            props.product.flavours!.map((flavour) => (
              <CollapsedProductExtra
                amountFlavors={props.product!.flavours!.length}
                product={flavour}
                onExtraExecute={calcTotalLocalProduct}
              />
            ))}
        </div>
      }
      footer={
        <>
          <ContainerPrice>
            <ButtonAdd onClick={props.onExecute}>
              Adicionar Item R$ {round2string(props.product?.total || 0)}
            </ButtonAdd>
          </ContainerPrice>
        </>
      }
      onNegativo={props.onClose}
      //onPositivo={}
    />
  );
}
